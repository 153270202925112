.card {
  &.shadow {
    border: none;
    box-shadow: $card-box-shadow !important;
  }
  &.carSection {
    text-align: center;
    cursor: pointer;
    .card-body {
      padding: 1rem;
      .card-img-top {
        margin-bottom: 1rem;
      }
    }
    &:hover {
      border-color: $blue;
    }
    &.active {
      border-color: $blue;
      background-color: $blueLight;
    }
    &.disabled {
      background-color: $gray-300;
      cursor: none;
      &:hover {
        border-color: $border-color;
      }
    }
  }
}

.card-footer {
  display: flex;
  flex-wrap: wrap;
  gap: $card-footer-gap;
  padding: 0 $card-spacer-x;
  padding-bottom: $card-footer-padding-bottom;
  border-width: $card-footer-border-width;
}

.card-text {
  color: $card-color-text;
}

.card-avtar {
  img {
    border: 1px solid $border-color;
    object-fit: $Image-object-cover;
    border-radius: $modal-avtar-radius;
  }
}
.cardBanner{
  .card-body { padding: $card-banner-space;}
  .card-Title,
  
  .card-footer {
    padding-right: $card-banner-space;
    padding-left: $card-banner-space;
    background: none;
  }
  &.disabled {
    background-color: $gray-300;
    cursor: none;
    &:hover {
      border-color: $border-color;
    }
  }
}