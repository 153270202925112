// scss-docs-start gray-color-variables


$white:    #fff !default;
$gray-100: #F8F8F8 !default;
$gray-300: #E7E7E7 !default;
$gray-400: #cdcdcd !default; 
$gray-500: #9a9a9a !default; 
$gray-600: #6D6E6F !default;
$gray-900: #333 !default; 
$black:    #000 !default;
 
$greenDark:#3F6D3B !default;
$greenDarker:#365C33 !default;

$Flamingo:#C34342 !default;
$FlamingoDark:#B43136 !default;
$FlamingoDarker:#942B28 !default;

$blueLight:#eaf3fa !default; 
$blueLighter:#91BCE3 !default;
$blueDark:#26639C !default;
$blueDarker:#2A5984 !default; 
$invalidbg:#FBEFEF !default; 



// scss-docs-start color-variables
$blue:    #2479C7 !default;
$red:     #B42318 !default;
$green:   #3E8438 !default;
$yellow:  #F6D645 !default;
$warning: #FF9500 !default;   
$Flamingo: #EB6663 !default;
$cyan:    #91BCE3 !default;


// scss-docs-start theme-color-variables
$primary:       $green !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $warning !default;
$danger:        $red !default;
$light:         $gray-400 !default;
$dark:          $gray-900 !default;

 
$colors: (
    "Flamingo":     $Flamingo,
    ) !default; 

$theme-colors: (
    "primary":    $primary, 
    "blue":       $blue,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark, 
    "theme":    $Flamingo,
    ) !default; 

    $grid-breakpoints: (
      xs: 0,
      sm: 576px,
      md: 768px,
      lg: 992px,
      xl: 1200px,
      xxl: 1400px,
      lg1024: 1025px 
    );

// Typography
$font-family-sans-serif:     'proxima-nova',sans-serif ;

// $font-size-base affects the font size of the body text
$spacer: 1rem !default;
$font-size-root:              null !default;
$font-size-base:              .9375rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;

$h1-font-size:                $spacer * 2 !default;
$h2-font-size:                $spacer * 1.5 !default;
$h3-font-size:                $spacer * 1.25 !default;
$h4-font-size:                $spacer * 1.125 !default;
$h5-font-size:                $spacer * 1.125 !default; 
$h6-font-size:                $spacer !default; 

$font-weight-bold:            600 !default;
$headings-font-weight:        600 !default;
$headings-line-height:        1.12 !default; 
$line-height-base:            1.25 !default;
  
// scss-docs-start display-headings
$display-font-sizes: (
  1: 2rem, 
  2: 1.5rem,
  3: 1.25rem,
  4: 1.125rem,
  5: 1.125rem, 
  6: 1rem
); 

$body-bg:                   $gray-100 !default;
$body-color:                $gray-900 !default;
$body-text-align:           null !default;
$font-family-proxima-nova: $font-family-sans-serif;
$font-family-icon:  "Font Awesome 5 Pro";

$box-shadow: 0 3px 36px rgba($black, .15) !default;
$box-shadow-sm: 0 2px 10px rgba($black, .10) !default; 

$border-color: $gray-400; 

$border-radius: .188rem !default;
$border-radius-sm:            .3125rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-pill:          50rem !default;

// buttons 
$btn-font-weight:         500 !default; 
$input-btn-padding-y:         .563rem !default;
$input-btn-padding-x:         1.1rem !default;
$input-btn-focus-width:         0 !default; 
$input-btn-padding-y-sm:      .454rem  !default; 
$input-btn-padding-x-sm:      .659rem !default; 
$btn-focus-box-shadow:none; 
$btn-active-box-shadow:none;   

$btn-change: "darken";
$btn-hover-change-bg: 1.5%; 
$btn-disabled-opacity:        .35!default;

$caret-width:                 .4em !default;
$caret-spacing:               .0em !default; 
$btn-dot-padding-y:      .47rem  !default;
$btn-dot-padding-x:      .47rem!default;
$btn-dot-width:      20px ;
 
$btn-group-radius:      $spacer * 1.5 !default;
$btn-group-label-x:   $spacer * .958 !default;
$btn-group-label-y:     $spacer * .688 !default;
    
// dropdown-border button
$dropdown-border-width:0;
$dropdown-border-color:$border-color;
$dropdown-padding-y:0;
$dropdown-box-shadow:0 2px 10px 0 rgba($black, .10) !default;
$dropdown-item-padding-x: .5rem !default;
$dropdown-item-padding-y: $spacer * .36 !default; 
$dropdown-link-color:  $body-color;
$dropdown-link-hover-bg:$blueLight;
$dropdown-link-hover-color:  $body-color;
$dropdown-link-active-color:    $body-color;   
$dropdown-link-active-bg:$blueLight; 

// Badges

// scss-docs-start badge-variables
$badge-font-size:                   .75em !default;
$badge-font-weight:                 500 !default;
$badge-color:                       $white !default;
$badge-padding-y:                   .313rem  !default;
$badge-padding-x:                   .57rem !default;
$badge-border-radius:               $border-radius !default;

$badge-color:                       $white !default;


// scss-docs-start form-check-variables
$form-check-input-width:                  1.25em !default;
$form-check-input-border: 1px solid $border-color; 
$form-check-input-border-radius:          .3125em !default;
$form-check-input-focus-border:  $border-color; 
$form-check-input-checked-bg-color:  $gray-900;

$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$white}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 11l4 4l9-9'/></svg>") !default;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='5'  fill='#{$gray-900}'  stroke-width='5' stroke='#{$white}' /></svg>") !default;

// scss-docs-start form-switch-variables
$form-switch-width:               3.125em !default;
$form-switch-padding-start:       $form-switch-width + 1em !default;
$form-switch-color:               $white !default;
$form-switch-focus-color:       $border-color !default; 
$form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='4' fill='#{$form-switch-color}'  stroke-width='0.2' stroke='#{$border-color}' /></svg>") !default; 
$form-switch-focus-bg-image:     $form-switch-bg-image;
$form-switch-checked-bg-image:   $form-switch-bg-image; 
$form-switch-checked-color:       $gray-100 !default;  


// form   input
$input-bg:      $white !default;
$input-padding-x:   .50rem !default;
$form-label-font-size:              1rem !default;
$form-label-margin-bottom:              .2rem !default; 
$form-label-font-weight:                500 !default;
$input-placeholder-color:               $gray-500;
$input-border-color:$border-color !default; 
$input-focus-border-color:$blue;   
$input-disabled-bg: $gray-300 !default;
$input-disabled-border-color: $border-color;

$input-focus-box-shadow:none; 
$form-feedback-icon-valid:none;
$form-feedback-icon-invalid:none; 
$form-feedback-icon-invalid-color:$body-color;
$form-feedback-icon-invalid-bg: #000 !default;
$form-text-font-size: 1rem;
$form-text-color:#777777;
// // input group
$input-group-addon-bg: $gray-300 !default;
$form-select-focus-box-shadow:none;   
$form-select-indicator-color:       $gray-900 !default;
$form-select-disabled-bg:       $gray-300 !default;
$form-select-disabled-border-color:       $border-color !default;  
$form-select-bg-position:           right 0.6rem center !default;

$form-select-bg-size:               9px 16px !default; // In pixels because image dimensions
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='#{$form-select-indicator-color}' d='M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z'/></svg>") !default;

$stepper-width : 158px;
// Close
$btn-close-color:            $dark !default;
$btn-close-opacity:          1 !default; 

 
$avtarimg-sm:32px;
$avtarimg-md:48px;
$avtarimg-lg:80px;
$Image-object-fit: contain;
$Image-object-cover: cover;
 
// Modals
$modal-btn-close-size: 18px;
$modal-btn-close-color: $gray-500;
$modal-btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 11' fill='#{$modal-btn-close-color}'><path d='M9.39453 8.48047C9.77539 8.83203 9.77539 9.44727 9.39453 9.79883C9.21875 9.97461 8.98438 10.0625 8.75 10.0625C8.48633 10.0625 8.25195 9.97461 8.07617 9.79883L5 6.72266L1.89453 9.79883C1.71875 9.97461 1.48438 10.0625 1.25 10.0625C0.986328 10.0625 0.751953 9.97461 0.576172 9.79883C0.195312 9.44727 0.195312 8.83203 0.576172 8.48047L3.65234 5.375L0.576172 2.29883C0.195312 1.94727 0.195312 1.33203 0.576172 0.980469C0.927734 0.599609 1.54297 0.599609 1.89453 0.980469L5 4.05664L8.07617 0.980469C8.42773 0.599609 9.04297 0.599609 9.39453 0.980469C9.77539 1.33203 9.77539 1.94727 9.39453 2.29883L6.31836 5.4043L9.39453 8.48047Z'/></svg>") !default;

$modal-content-border-width: 0;
$modal-inner-padding: $spacer * 1.25 !default; 
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-content-border-width;
$modal-header-title-size:$h3-font-size;
$modal-padding-b: .29em;
$modal-body-padding-y: 10px;
$modal-body-padding-x: $modal-inner-padding;  
$modal-footer-bg-color:$white; 
$modal-content-box-shadow: 0 8px 40px 0 rgba($black, .2) !default;   
$modal-content-border-radius:       $border-radius-sm !default;
$modal-footer-y: .85em;
$modal-footer-x: $modal-inner-padding;  
$modal-footer-margin-between:       0 !default;
$modal-footer-gap: 0.9rem;  
$modal-avtar-size: $avtarimg-md;
$modal-avtar-margin-x: auto;
$modal-avtar-radius: 50%;
$modal-avtar-img-radius: $Image-object-fit ;
$modal-avtar-img-object-fit:$Image-object-cover;

$modal-sm:                          480px !default; 
$modal-md:                          500px !default;
$modal-lg:                          768px !default;
$modal-xl:                          900px !default; 

// for content area 
$content:                          1050px !default;
$midcontent:                          930px !default;
$midcontent-new:                      844px !default;  

// Tooltips
$tooltip-font-size:                 .75rem !default;
$tooltip-max-width:                 360px !default;
$tooltip-bg:                        $gray-900 !default;
$tooltip-padding-y:                 $spacer * .532 !default;
$tooltip-padding-x:                 $spacer * .8 !default;
$tooltip-arrow-width:               1rem !default;
$tooltip-arrow-height:              .5rem !default; 


// List group
$list-group-item-padding-y:         $spacer * .563 !default;
$list-group-item-padding-x:         $spacer * .91 !default;

$list-group-item-padding-Hy:         0.56rem !default;
$list-group-item-padding-Hx:         0.966rem !default;    

$list-group-color:                  $body-color !default;
$list-group-hover-bg:               $gray-100 !default;
$list-group-border-color:               $gray-500 !default;
$list-group-hover-bg:               $white !default;
$list-group-hover-border-color:    $blue !default;
$list-group-active-color:          $list-group-color !default;
 $list-group-active-bg:              $blueLight !default;
$list-group-active-border-color:    $blue !default; 
$list-group-disabled-color:         $gray-600 !default;
$list-group-disabled-bg:           $gray-300 !default;    



// Navs 
// scss-docs-start nav-variables
$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-font-size:                null !default;
$nav-link-font-weight:              500 !default;
$nav-link-color:                    $dark !default;
$nav-link-hover-color:              $Flamingo !default; 
$nav-link-disabled-color:           $gray-600 !default;
$link-color:                        #444 !default;

// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y:                  $spacer * .31 !default; 
$navbar-padding-x:                  null !default;
$navbar-nav-link-padding-x:         1.5rem !default;
$nav-vertical-link-padding-y:  .75rem !default; 
$nav-vertical-link-padding-x:   0 !default;   
$navbar-brand-font-size:            $font-size-base !default;
$navSvgSpace: 33px;



// scss-docs-start navbar-theme-variables
$navbar-dark-color:                 rgba($white, 1) !default;
$navbar-dark-hover-color:           rgba($white, 1) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, 1) !default;
$navbar-dark-toggler-border-color:  rgba($white, 1) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;

$navbar-light-color:                rgba($dark, 1) !default;
$navbar-light-hover-color:          rgba($Flamingo, 1) !default;
$navbar-light-active-color:         rgba($Flamingo, 1) !default;
$navbar-light-disabled-color:       rgba($Flamingo, .5) !default;
$navbar-light-toggler-border-color: rgba($dark, .1) !default;  
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#737373'  stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;

$navbar-light-brand-color:                $navbar-light-color !default;
$navbar-light-brand-hover-color:          $navbar-light-active-color !default;
$navbar-dark-brand-color:                 $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;


// Alerts
$alert-padding-y:               0.688rem !default;
$alert-padding-x:               .6rem !default;
$alert-bg-scale:                0% !default; 
$alert-border-scale:            0% !default;
$alert-color-scale:            0% !default; 
$alert-border-radius:           $border-radius-sm;  


// Pagination

$pagination-color: #808080 !default;

$pagination-bg:                     none !default;
$pagination-border-width:          0 !default;
$pagination-padding-y:              .25rem !default;
$pagination-padding-x:              0 !default;
$pagination-border-radius:          0;
//$pagination-focus-bg:               $Flamingo !default;
$pagination-focus-bg:               '' !default;
$pagination-focus-color:            $white !default;
$pagination-active-color:           $white !default;
$pagination-active-bg:               $pagination-focus-bg !default;
$pagination-disabled-color:         $gray-500 !default;
$pagination-disabled-bg:            null !default;
$pagination-disabled-border-color:  null !default;
$pagination-border-radius: 50%;  
$pagination-disabled-bg:            none !default; 
$PageLinkwitdh:            28px; 
 

// Cards 
$card-border-color:                 $border-color !default; 
$card-spacer-y:                    $spacer * 1.5 !default; 
$card-spacer-x:                     $spacer * 1.5 !default;  
$card-cap-bg:                       $white;
$card-footer-gap:                   1rem; 
$card-color-text:                      $body-color; 
$card-footer-border-width :0;
$card-footer-padding-bottom: $spacer * 1.5 !default;  
$card-box-shadow:  0 2px 8px 0 rgba($black, .2) !default;
$card-banner-space:                  $spacer * 1.2 !default; 

// Tables
$table-head-color:#686868;
$table-head-bg: #f8f8f8;
$tableBroderColor:#e7e7e7;


$table-cell-padding-y:        .602rem !default; 
$table-cell-padding-x:        .65rem !default;

$table-th-font-weight:        500 !default;
$table-cell-head-padding-y:        1rem !default;
$table-cell-head-padding-x:       $table-cell-padding-x;
$table-headtext-color: $table-head-color; 
$table-head-bg: $table-head-bg; 
$table-head-border: 1px;

$table-font-size:        .9375rem; 
$table-font-weight:        300; 
$table-border-color: $tableBroderColor !default; 
$table-hover-bg-factor:       .019 !default;
$table-border-radius:       $border-radius * 1.2 !default;
$table-foot-weight: 600;
// $table-hover-bg: $table-hover-color;

// mobile view size
$mobile-font-size-base: 1.125rem !default;



