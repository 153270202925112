//
// Check/radio
//

.form-check {
  &-label {
    margin-bottom: 0;
  }
  &-input {
    margin-top: 0;

    &[type="checkbox"] {
      margin-top: 0;
    }
  }
}

.form-check-input {
  &[type="checkbox"],
  &[type="radio"] {
    &:hover {
      border-width: 2px;
    }
    &:checked {
      border-color: $gray-500;
      border-width: 2px;
      &:disabled,
      &.disabled {
        border-width: 1px;
        border-color: $body-color;
      }
    }
    &:disabled,
    &.disabled {
      border-width: 1px;
    }
  }
}

.form-switch {
  &:after {
    content: "";
    height: 1px;
    width: 100%;
    display: block;
    clear: both;
    visibility: hidden;
  }
  .form-check-input {
    height: 30px;
    background-color: $gray-400;
    &:hover {
      border-width: 1px;
      border-color: $border-color;
    }
    &:checked {
      border-width: 1px;
      background-color: $green;
      border-color: $greenDarker;
    }
  }
  .form-check-label {
    b {
      font-weight: 500;
      font-size: $h4-font-size;
    }
  }
  p {
    color: $form-text-color;
  }
}
 