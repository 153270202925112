.btn-group {
  &.show:not(.dropstart) {
    .btn {
      &.dropdown-toggle-split {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .btn {
    + .dropdown-toggle {
      border-left-color: inherit;
      z-index: 1;
    }
  }

  .btn-dot {
    padding: $btn-dot-padding-y $btn-dot-padding-x;
    min-width: $btn-dot-width;
    justify-content: center;
    &:after {
      display: none;
    }
  }
}

.btn-group {
  label.btn {
    border-radius: $btn-group-radius;
    background: $white;
    border-color: $border-color;
    color: $dark;
    font-weight: normal;
    padding: $btn-group-label-y $btn-group-label-x;
  }
  .btn-check:focus + label.btn {
    background: $white;
    border-color: $border-color;
    color: $dark;
  }
  .btn-check:checked:focus + label.btn,
  .btn-check:active + .btn,
  .btn-check:checked + label.btn {
    color: $white;
    border-color: $greenDarker;
    background-color: $primary;
  }
}
