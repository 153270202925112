
.daterangepicker {  
  &.dropdown-menu{ border: 1px solid rgba(0,0,0,.15); box-shadow: 0 6px 12px rgb(0 0 0 / 18%); max-width: 740px;  padding: 4px;
     font-size: 14px; margin-top: 2px; font-family: inherit;
    .vg-default-small-dropdown { padding-right: 0;   background: none; border: none;
      select{ @extend .form-select; border:1px solid #cdcdcd!important; width: 100%!important; padding: 0 18px 0 5px !important; font-size: 12px;
        &:focus{border-color: #2a90d6 !important;}
      }
    }
  }
  .range_inputs {
    .applyBtn{ float: right;}
    .btn{max-width: 82px;}
  
  }   
}
  