.timepicker_wrp input::placeholder {
    color: #999;
  }
 
  .timepicker_wrp {
    position: relative;
    background: #fff;
    width: 100%;
    border-radius: 3px;
 
    >svg {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      margin-top: 1px;
      font-size: 15px;
      color: #777777;
      z-index: 0;
    }
 
    .form-control {
      text-align: left;
    }
  }
 
  .datetimeicon {
    position: absolute;
    width: 35px;
    height: 43px;
    right: 0;
    top: 2px;
    text-align: center;
 
    &::before {
      cursor: pointer;
      color: #808080 ;
      color: var(--text-neutral-secondary);
      font-size: 15px;
      content: "\f017";
      position: absolute;
      width: 35px;
      height: 36px;
      left: 0px;
      top: 2px;
      font-family: "Font Awesome 6 Pro";
      line-height: 29px;
      text-align: center;
      font-style: normal;
    }
 
  }
 
  html .ui-timepicker-standard {
    box-shadow: 0 2px 12px 2px rgb(0 0 0 / 15%);
    border: 0;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    background: #fff;
    min-width: 132px;
    margin-left: -95px;
    margin-top: -6px;
 
    .ui-timepicker {
      min-width: 132px;
    }
 
    a {
      background: transparent;
      margin-bottom: 1px;
      color: #333 !important;
      border: 0 !important;
      background-image: none !important;
      padding: 6px 0 6px 0px !important;
      border-radius: 0 !important;
      text-align: center;
      font-family: "proxima-nova", sans-serif !important;
 
      &.ui-state-hover {
        background-color: #eee;
        color: #333 !important;
      }
    }
 
 
 
    .ui-timepicker-viewport {
      display: block;
      width: auto !important;
      margin-right: 1px;
      margin-left: -1px;
    }
  }
 
  .datepicker-new-default {
    .form-control {
      cursor: pointer !important;
      position: relative;
      background: transparent !important;
      z-index: 3;
      text-align: left;
    }
 
    .check-cal-icon {
      line-height: normal;
      cursor: pointer;
      z-index: 1;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 18px;
    }
  }
 
  @mixin responsiveView {
    .vg_ismobile .datetimeicon {
      position: absolute;
      width: 35px;
      height: 43px;
      right: 0;
      top: 0px;
      text-align: center;
 
      &::before {
        cursor: pointer;
        color: #808080 !important;
        font-size: 18px;
        content: "\f017";
        position: absolute;
        width: 35px;
        height: 36px;
        left: 0px;
        top: 2px;
        font-family: 'Fontawesome pro 300';
        line-height: 36px;
        text-align: center;
        font-style: normal;
      }
 
    }
 
    .timepicker_wrp {
      max-width: 100%;
      height: 48px;
      font-size: 16px;
    }
 
  }
  // end responsiveview
 
  .ui-timepicker-container {
    position: absolute;
    overflow: hidden;
    box-sizing: border-box;
  }
 
  .ui-timepicker {
    box-sizing: content-box;
    display: block;
    height: 205px;
    list-style: none outside none;
    margin: 0;
    padding: 0 1px;
    text-align: center;
  }
 
  .ui-timepicker-viewport {
    box-sizing: content-box;
    display: block;
    height: 205px;
    margin: 0;
    padding: 0;
    overflow: auto;
    overflow-x: hidden;
    /* IE */
  }
 
  .ui-timepicker-standard {
    /* overwrites .ui-widget */
    font-family: Verdana, Arial, sans-serif;
    font-size: 1.1em;
    /* overwrites .ui-widget-content */
    background-color: #FFF;
    border: 1px solid #AAA;
    color: #222;
    /* overwrites .ui-menu */
    margin: 0;
    padding: 2px;
    z-index: 99999 !important;
  }
 
  .ui-timepicker-standard a {
    border: 1px solid transparent;
    color: #222;
    display: block;
    padding: 0.2em 0.4em;
    text-decoration: none;
  }
 
  .ui-timepicker-standard .ui-state-hover {
    /* overwrites .ui-state-hover */
    background-color: #DADADA;
    border: 1px solid #999;
    font-weight: normal;
    color: #212121;
  }
 
  .ui-timepicker-standard .ui-menu-item {
    /* overwrites .ui-menu and .ui-menu-item */
    margin: 0;
    padding: 0;
  }
 
  .ui-timepicker-corners,
  .ui-timepicker-corners .ui-corner-all {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }
 
  .ui-timepicker-hidden {
    /* overwrites .ui-helper-hidden */
    display: none;
  }
 
  .ui-timepicker-no-scrollbar .ui-timepicker {
    border: none;
  }
 
  .timepicker_wrp {
    width: 100% !important;
  }