/*Loader css Start End*/
$darkbdr: #CC4744 !default;
$lightbdr: transparentize(#CC4744, .7)  !default;

.loaderNew {
  position: fixed;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  z-index: 100005; display: block;
}
.loader-imgNew {
  z-index: 100005;
  margin: -25px 0 0 -25px;
  font-size: 10px;
  top: 50%;
  left: 50%;
  position: absolute;
  text-indent: -9999em;
  border-style: solid;
  border-top-width: 0.7em;
  border-right-width: 0.7em;
  border-bottom-width: 0.7em;
  border-top-color: $lightbdr;
  border-right-color: $lightbdr;
  border-bottom-color: $lightbdr;
  border-left-width: 0.7em;
  border-left-color: $darkbdr;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.loader-imgNew,
.loader-imgNew:after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loaderNew * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.loaderNew *:before,
.loaderNew *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.loaderNew.loader-block {
  display: block;
}
/* New Loader css */

.ingridLoading {
  position: relative;
  height: 40vh;
  .loaderNew {
    position: fixed;
  }
}
