/* Remove outline for non-keyboard :focus */
*:focus:not(.focus-visible) {
    outline: none;
}

input[type="text"] {
    box-shadow: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

button:focus {
    outline: 0;
}

.mobile-contextmenu.dropdown .dropdown-item i {
    display: none;
}

.titleFormobile {
    display: none;
}

.vcMobile {
    .vConnectMainWrap {

        &.rightSideOnly {
            .upgradeBlockWrap {
                bottom: 0;
            }

            max-width: 100%;
            position: static;
            border: none;

            .rightSideOnly-header {
                display: none;
            }


            .chatarea {
                .chatListBlock {
                    max-width: 100%;
                    height: 100vh;
                    display: flex;
                    border: 0px;
                    flex-direction: column;

                    &-searchWrap {
                        order: 2;
                        padding-top: 15px;
                    }

                    .rightSideOnly-nav {
                        order: 1;
                    }

                    .chatList {
                        order: 3;
                    }

                    .chatList-groupBlock {
                        height: calc(100vh - 222px);
                    }

                    .chatList-group_header {
                        border-top: 1px solid var(--border_neutral_default);
                        border-bottom: 1px solid var(--border_neutral_default);
                    }

                    .chatList-person_right {
                        width: 77%;
                        flex-grow: 1;
                    }
                }

                .messagesArea-header_moreVertical {
                    display: block;

                    .dropdown {
                        &-toggle {
                            border: none;
                            outline: none;
                            padding: 0;
                        }
                    }
                }

                .messagesArea-header_nameArea {
                    width: 100%;
                }
            }

            &.chatActive {
                height: 100%;

                .right-mobileHeaderDetailBlock {
                    display: flex;
                    height: 44px;
                }

                .chatListBlock {
                    display: none;
                }

                .messagesArea {
                    &.flex-fill {
                        width: 100% !important;
                        height: 100%;
                    }

                    &-middle {
                        height: 100%;
                    }

                    &-header_avGroup {
                        justify-content: center;
                    }

                    &-header_name {
                        width: auto;
                    }
                }

                .collapsibleChat {
                    display: block !important;
                    // bottom: 38px;

                    &.mobileActiveChat {
                        .iconchat {
                            right: 16%;
                        }

                        .ck-editor {
                            justify-content: start;

                            &.ck-reset .ck-toolbar .cke_button.cke_button_Send {
                                display: block !important;
                            }

                            &__main {
                                width: 86% !important;
                            }
                        }
                    }
                }

                .ckeditor_2 .ck-editor.ck-reset .ck-editor__main .ck-editor__editable {
                    min-height: 50px;
                }

                .vcDrop-wrap {
                    .titleFormobile {
                        display: block;
                        color: var(--text_neutral_default);
                        // border-bottom: 1px solid var(--border_neutral_default);
                        font-size: 20px;
                        font-weight: 500;
                        line-height: 24px;
                        padding: 16px 12px;
                    }
                }

                .vcDrop-group-item {
                    color: var(--text_neutral_default);
                    border-bottom: 1px solid var(--border_neutral_default);
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                   
                }

                &.detailsActive {
                    .messagesArea {
                        display: none;
                    }

                    .rightSlide {
                        display: block;
                        width: 100%;
                        max-width: 100%;
                        height: 100%;

                        .rightside {
                            width: 100%;
                            max-width: 100%;

                            .accordion-item .accordion-header {}

                            // .accordion-button {
                            //     box-shadow: none !important;
                            // }

                            .businessLocationBlock {
                                .locationList {
                                    max-width: 96%;

                                    .location {
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 100%;
                                    }
                                }
                            }
                        }
                    }

                    .rightside-wrapper .services-list-wrap.pastAppointmentsWrap .appointment-detail .dropdown,
                    .rightside-wrapper .services-list-wrap.purchases-wrap .appointment-detail .dropdown,
                    .rightside-wrapper .member-list-wrapper .dropdown {
                        display: block;
                        height: 100%;
                        width: 100%;
                        top: 0px;

                        .dropdown-toggle {
                            height: 100%;
                            width: 100%;
                        }

                        .fa-solid.fa-ellipsis-vertical {
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            margin-top: -10px;

                            &::before {
                                content: '\f105';
                                color: var(--text_neutral_default);
                            }
                        }
                    }

                    .rightside-wrapper .appointment-detail .service-wrap.serviceCard {
                        width: 94%;
                    }

                    .rightside-wrapper .appointment-detail .service-wrap.serviceCard>.bottom-iconsWrap::after {
                        font-family: "Font Awesome 6 Pro";
                        font-weight: 900;
                        content: '\f105';
                        position: absolute;
                        right: -16px;
                        top: 40%;
                    }
                }
            }

            /* CK Editor */
            .collapsibleChat {
                display: none;
                padding: 0 !important;
                background-color: white;

                .ck-reset.ck-editor {
                    box-shadow: none;
                    max-width: 100%;

                    .ck-editor__main {
                        // margin-right: 35px;
                        // width: 265px;
                        width: 100%;
                    }

                    .ck-toolbar {
                        .custom-emoji {
                            position: static;
                        }

                        .ck-toolbar__items {
                            .cke_button {
                                display: none;
                            }
                        }

                        .cke_button {
                            &_note {
                                position: absolute;
                                right: 20px;
                                display: block;
                                bottom: 65px;
                            }

                            &.cke_button_Send {
                                display: block !important;
                                border-radius: 100%;
                                font-size: 0;
                                position: absolute;
                                right: 0px;
                                bottom: 10px;
                                padding: 0px 9px 0px 10px;
                                min-height: 35px;
                                min-width: 35px;
                                height: 35px;
                                width: 35px;

                                &:before {
                                    content: "\f061";
                                    font-size: 18px;
                                    font-family: "Font Awesome 5 Pro";
                                    font-weight: 900;
                                    line-height: 37px;
                                }
                            }
                        }
                    }
                }

                &.mobileActiveChat {
                    .ck-reset.ck-editor .ck-toolbar .cke_button.cke_button_Send {
                        display: block !important;
                    }

                    .ck-editor__main {
                        width: 86% !important;
                    }

                    .iconchat {
                        right: 16%;
                    }
                }
            }

            .welcomeScreen {
                height: calc(100% - 119px);

                &-Blank {
                    height: calc(100% - 44px);
                }

                .collapsibleChat {
                    display: block;
                }
            }
        }
    }


    .add-member-dialogue {
        .select-employee-wrap .select2-selection--multiple .select2-selection__choice {
            width: 135px;
        }
    }

    .vConnectMainWrap .chatList-person:active .chatList-person_dotMenu,
    .vConnectMainWrap .chatList-person:hover .chatList-person_dotMenu {
        display: none;
    }

    .vConnectMainWrap .chatList-person:hover,
    .vConnectMainWrap .rightside-wrapper .appointment-detail:active,
    .vConnectMainWrap .rightside-wrapper .appointment-detail:hover,
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.pastAppointmentsWrap .appointment-detail:hover {
        background: transparent !important;
    }

    &:not(.vcTab-portrait) {
        .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControlWrap {
            position: fixed;
            bottom: 0;
            width: 100%;
            top: auto !important;
            border-radius: 20px 20px 0px 0px !important;
            background: #F8F8F8 !important;
            padding: 16px;

            .editControl {
                color: #333;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                padding: 12px 16px;
                border-bottom: solid 1px #cdcdcd;
                display: flex;
                align-items: center;
                
                width: auto;
                &:first-child:not(.selected) {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
                &:last-child:not(.selected) {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    border-bottom: none;
                }

                .editControlText {
                    display: inline-block;
                }

                i {
                    width: 25px;
                    text-align: center;
                    font-size: 20px;
                    margin-right: 10px;
                }
                &:hover {
                    background: var(--bkg_neutral_secondary) !important;
                }
            }

            .editControl-secondary {
                .editControl {
                    background: var(--bkg_neutral_default);
                    &:only-child {
                        border-bottom: none !important;
                    }
                }
            }

            .editControl-primary {
                .editControl {
                    padding: 4px !important;
                    height: auto;
                    border-bottom: none;
                }
            }
        }
    }

    &.noresultfound {
        .employee-multilocation-select.select2-container .select2-dropdown {
            top: 0px !important;
        }
    }

    .vcLinkWrapForMobile {
        position: fixed;
        z-index: 9;
    }
}

.mobileChatBack {
    display: none;
}

@media only screen and (max-width: 767px) {

    .image-popup-wrap .modal-content .image-gallery-slide-wrapper {
        height: calc(100vh - 50px);
    }

    .image-popup-wrap {
        .modal-body {
            padding: 0;
        }
    }

    .vcMobile .btn-select-done {
        padding-bottom: .797rem !important;
        padding-top: .797rem !important;
    }

    .VC-customerChatOpen {
        overflow: hidden;
    }

    .customerChatWindow.customerChatWindow-mobile {
        .vConnectMainWrap {
            height: 100vh;
            max-width: 100%;

            .smallChatBox {
                &Wrap {
                    height: 100%;
                    width: 100%;
                    right: auto;
                    left: auto;
                    border-radius: 0;
                    box-shadow: none;

                    .collapsibleChat {
                        .ck-reset.ck-editor {
                            position: relative;
                        }

                        .EmojiPickerReact {
                            left: 25px;
                        }
                    }
                }

                &-header {
                    display: none;
                }

                &-middle {
                    &_nameArea {
                        padding: 15px 15px 15px 0px;
                    }

                    .messagesArea {
                        &-header {
                            .mobileChatBack {
                                display: block;
                                padding: 15px;
                                cursor: pointer;
                            }

                            .messagesArea-header_nameArea {
                                display: flex;
                                align-items: center;
                            }

                        }

                        &-middle {
                            height: calc(100vh - 130px);
                        }

                        &-footer {
                            .typeMsgText {
                                top: -30px;
                            }
                        }
                    }

                    .collapsibleChat {
                        // bottom: 39px;
                        position: absolute;
                    }
                }
            }

            .chatarea .messagesArea-middle .messagesBox.messagesEditWrap .EmojiPickerReact {
                left: 5px !important;
            }
        }

        .vConnectMainWrap .chatarea .messagesArea-header_avGroup {
            width: 89%;
        }
    }
}

.vcMobile {

    .vConnectMainWrap .messagesArea-footer .gifPluginBlock,
    .vConnectMainWrap.rightSideOnly .gifPluginBlock,
    .vConnectMainWrap .gifPluginBlock {
        width: 100%;
        left: 0 !important;
        margin-left: 0;
        background: var(--bkg_neutral_default) !important;
        padding-bottom: 10px;

        .reactGiphySearchbox-poweredByGiphy {
            display: none;
        }

        .reactGiphySearchbox-listWrapper {
            padding: 0 16px;
        }

        .reactGiphySearchbox-searchForm-form {
            padding-top: 16px;
            padding-left: 10px;
            padding-right: 14px;
            margin: 0 auto;

            &::before {
                top: 27px;
            }
        }

        .reactGiphySearchbox-componentWrapper {
            width: 100% !important;
        }

        .reactGiphySearchbox-searchForm-input {
            border-radius: 12px;
            background: #F7F8FC;
            height: 38px;
        }

        .reactGiphySearchbox-listWrapper {

            [data-testid="MasonryLayoutContainer"] {
                margin: 0 auto;
            }
        }

    }
}

.vcMobile {
    .chatActive.rightSideOnly .chatarea .messagesEditWrap {
        .EmojiPickerReact {
            margin-left: -35px;
        }
    }

    .vConnectMainWrap.rightSideOnly .welcomeScreen {
        .gifPluginBlock {
            margin-left: 0;
        }
    }
}

/*- START: ipad -*/
.vcTab {

    //    padding-top: 35px;
    .rightside-wrapper {
        // height: 100vh;
    }

    .vConnectMainWrap .chatarea .chatListBlock .chatList-person_dotMenu,
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.pastAppointmentsWrap .appointment-detail .dropdown,
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.purchases-wrap .appointment-detail .dropdown {
        display: block;
    }

    .vConnectMainWrap .chatList-person .chatList-person_time {
        position: absolute;
        right: 15px;
        bottom: 14px;
        // background: #fff;
        padding: 2px;
    }

    .vConnectMainWrap .chatarea .chatListBlock .chatList-person_messages {
        width: 67%;
    }

    .vConnectMainWrap .chatarea .chatListBlock .chatList-person_dotMenu.show.dropdown~.chatList-person_right>.chatList-person_right-top>.chatList-person_time {
        display: block;
    }

    .vConnectMainWrap .chatList-person:active,
    .vConnectMainWrap .chatList-person:hover {
        .chatList-person_dotMenu {
            display: block;
        }

        .chatList-person_time {
            display: block !important;
            opacity: 1;
        }
    }

    .vConnectMainWrap .chatList-person.active {
        .chatList-person_dotMenu {
            display: block;
        }

        .chatList-person_time {
            display: block;
        }
    }

    .vConnectMainWrap .chatList-person:hover,
    .vConnectMainWrap .rightside-wrapper .appointment-detail:active,
    .vConnectMainWrap .rightside-wrapper .appointment-detail:hover,
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.pastAppointmentsWrap .appointment-detail:hover {
        background: transparent !important;
    }

    .vConnectMainWrap {
        .leftside,
        .messagesArea // .rightside-wrapper

            {
            // height: 100vh !important;
            height: auto;
            min-height: 100%;
        }

        .chatarea .chatListBlock .chatList-groupBlock {
            height: calc(100vh - 117px) !important;
        }

        // // .chatarea .chatListBlock.convRight-open { height: 100%;}
        // .chatarea .chatListBlock.convRight-open { height: calc(100% - 40px) !important;}
        .chatarea .messagesArea-middle {
            height: calc(100vh - 218px);
        }
    }

    //    .select2-container.select2-container--default.select2-container--open.ddlSelectCustomer11.driveFormMselect.vcEmployeeSelect,
    //    .select2-container.select2-container--default.select2-container--open.vcEmployeeSelect.ddlEmployee {
    //         .select2-dropdown--below {
    //             top: 50px !important;
    //         }
    //    }

    .vConnectMainWrap .chatarea .chatListBlock {
        height: 100vh;
    }

    &-browser {

        .vConnectMainWrap .chatarea .chatListBlock .chatList-person_dotMenu,
        .vConnectMainWrap .rightside-wrapper .services-list-wrap.pastAppointmentsWrap .appointment-detail .dropdown,
        .vConnectMainWrap .rightside-wrapper .services-list-wrap.purchases-wrap .appointment-detail .dropdown {
            display: block;
        }

        .vConnectMainWrap .chatList-person .chatList-person_time {
            position: absolute;
            right: 15px;
            bottom: 14px;
            // background: #fff;
            padding: 2px;
        }

        .vConnectMainWrap .chatarea .chatListBlock .chatList-person_messages {
            width: 67%;
        }

        .vConnectMainWrap .chatarea .chatListBlock .chatList-person_dotMenu.show.dropdown~.chatList-person_right>.chatList-person_right-top>.chatList-person_time {
            display: block;
        }

        .vConnectMainWrap .chatList-person:active,
        .vConnectMainWrap .chatList-person:hover {
            .chatList-person_dotMenu {
                display: block;
            }

            .chatList-person_time {
                display: block !important;
                opacity: 1;
            }
        }

        .vConnectMainWrap .chatList-person.active {
            .chatList-person_dotMenu {
                display: block;
            }

            .chatList-person_time {
                display: block;
            }
        }

        .vConnectMainWrap .chatList-person:hover,
        .vConnectMainWrap .rightside-wrapper .appointment-detail:active,
        .vConnectMainWrap .rightside-wrapper .appointment-detail:hover,
        .vConnectMainWrap .rightside-wrapper .services-list-wrap.pastAppointmentsWrap .appointment-detail:hover {
            background: transparent !important;
        }

        //     padding-top: 0;

        //     .vConnectMainWrap .leftside,
        //     .vConnectMainWrap .messagesArea {
        //         height: 100vh;
        //     }
        //     .rightSlide {
        //         height: 100% !important;
        //     }
        //     .vConnectMainWrap .chatarea .messagesArea-middle {
        //         height: calc(100vh - 218px);
        //     }
        //     .vConnectMainWrap .chatarea .chatListBlock .chatList-groupBlock {
        //         height: calc(100vh - 140px) !important;
        //     }
        //     .vcTab .select2-container.select2-container--default.select2-container--open.ddlSelectCustomer11.driveFormMselect.vcEmployeeSelect,
        //     .vcTab .select2-container.select2-container--default.select2-container--open.vcEmployeeSelect.ddlEmployee {
        //         .select2-dropdown--below {
        //             top: 15px !important;
        //         }
        //     }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .rightSlide.stencilBlock-rightSlide {
        display: none;
    }

    .vcMobile {
        .vConnectMainWrap {
            .chatarea {
                .chatListBlock {
                    .chatList-person_right {
                        // width: 100%;
                    }
                }
            }

            &.rightSideOnly {
                .collapsibleChat {
                    .ck-reset.ck-editor {
                        .ck-editor__main {
                            // width: 90%;
                        }
                    }
                }
            }
        }
    }

    .vcTab {
        .vConnectMainWrap {
            .typeChatWrap {
                z-index: 9;
            }

            // .chatarea .messagesArea-footer .EmojiPickerReact {
            //     left: -110px;
            // }

            // .messagesArea-footer .gifPluginBlock {
            //     left: -60px;
            // }
        }
    }


}

.vcTab,
.vcTab-browser {
    .vConnectMainWrap .employeeSelectWrap .select2.select2-container.select2-container--default.ddlSelectCustomer11.vcEmployeeSelect {
        width: 100% !important;
    }
}


@media only screen and (max-width: 1024px) {

    .vcTab,
    .vcTab-browser {
        .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox-block {
            max-width: 100% !important;
        }
    }
}

/*- END: ipad -*/

.customerChatWindow-mobile .vConnectMainWrap .gifPluginBlock {
    width: 100%;
    left: 0 !important;
    margin-left: 0;
    background: var(--bkg_neutral_default) !important;
    padding-bottom: 10px;

    .reactGiphySearchbox-poweredByGiphy {
        display: none;
    }

    .reactGiphySearchbox-listWrapper {
        padding: 0 16px;
    }

    .reactGiphySearchbox-searchForm-form {
        padding-top: 16px;
        padding-left: 10px;
        padding-right: 14px;
        margin: 0 auto;

        &::before {
            top: 27px;
        }
    }

    .reactGiphySearchbox-componentWrapper {
        width: 100% !important;
    }

    .reactGiphySearchbox-searchForm-input {
        border-radius: 12px;
        background: #F7F8FC;
        height: 38px;
    }

    .reactGiphySearchbox-listWrapper {

        [data-testid="MasonryLayoutContainer"] {
            margin: 0 auto;
        }
    }

}

/*- START: keypad open in iPad-*/
.iosmobiledevice {
    height: 100%;

    .vcTab,
    .vcTab-browser {
        &.keywordopen {
            height: 100%;

            .Reactinclude,
            .vConnectMainWrap,
            .vConnectMainWrap .row,
            .chatarea,
            .vConnectMainWrap .chatarea>div.d-flex,
            .vConnectMainWrap .chatarea .chatListBlock.convRight-open {
                height: 100%;
            }

            .chatList {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                overflow: hidden;
            }

            .vConnectMainWrap .chatarea .chatListBlock .chatList-groupBlock {
                height: 100% !important;
                overflow: auto;
                flex-grow: 1;
            }

            .vConnectMainWrap .chatarea .chatListBlock.convRight-open {
                height: 100% !important;
                left: 0;
                opacity: 1;
                width: 319px;
                display: flex;
                flex-direction: column;
            }

            .vConnectMainWrap .leftside,
            .vConnectMainWrap .messagesArea {
                height: 100%;
            }

            .vConnectMainWrap .chatarea .messagesArea-middle {
                height: calc(100% - 218px);
            }

            .vConnectMainWrap .rightside {
                height: 100%;
            }
        }
    }
}

/*- END: keypad open in iPad-*/

/*Add hariom by */
.vcMobile {

    .messagesEditWrap .mobileListmenu,
    .messagesEditWrap .iconchat {
        display: none;
    }

    .add-member-dialogue {
        overflow: hidden !important;
        padding: 0 !important;
        display: flex !important;
        align-items: flex-end;

        .modal-mb-half {
            min-height: auto !important;
            margin: 0 !important;
            max-width: 100%;
            width: 100%;
        }
    }

    //for mobile responsive bottom to top

    .modal-mb-half {
        margin-top: 0px;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 0;
        // padding: 0 !important;
        height: 100%;

        &.modal-dialog-centered {
            align-items: flex-end;
        }

        .modal-dialog {
            padding: 50px 0 0 0;
            min-width: 100%;
            justify-content: flex-end;
        }

        .modal-content {
            border-radius: 10px 10px 0px 0px;
        }

        .modal-header {
            padding: 16px 16px 0 !important;

            button.btn-close {
                display: none;
            }
        }

        .modal-body {
            height: inherit;
            max-height: inherit;
            padding: 16px 16px;
        }

        .modal-footer {
            flex-direction: column;
            // gap: 0;
            padding: 16px 16px;
            background-color: transparent !important;

            .btn {
                height: 50px;
                width: 100%;
            }

            .btn.btn-secondary {
                margin-top: 0;
                margin-bottom: 0;
            }

            .btn+.btn {
                margin-left: 0;
                margin-top: 12px;
            }
        }
    }

    .modal {
        &.fade {
            .modal-mb-half {
                &.modal-dialog {
                    -webkit-transform: translate(0, 100%) !important;
                    -ms-transform: translate(0, 100%) !important;
                    -o-transform: translate(0, 100%) !important;
                    transform: translate(0, 100%) !important
                }
            }
        }

        &.show,
        &.in {

            .modal-mb-half {
                &.modal-dialog {
                    -webkit-transform: translate(0, 0%) !important;
                    -ms-transform: translate(0, 0%) !important;
                    -o-transform: translate(0, 0%) !important;
                    transform: translate(0, 0%) !important;
                }
            }
        }
    }

    &.modal-open {

        .modal {
            overflow: hidden !important;
            padding: 0px !important;
        }
    }


    .ckeditor_1 {
        flex: 1;
        max-width: 100%;
    }

    .vConnectMainWrap.rightSideOnly .collapsibleChat .ck-reset.ck-editor .ck-toolbar .cke_button.cke_button_Send {
        display: none !important;
    }

    .vConnectMainWrap.rightSideOnly .collapsibleChat .ck-reset.ck-editor {
        background-color: transparent !important;
    }

    .ck-reset.ck-editor.ck-rounded-corners.note-active {

        .ck-placeholder:before,
        .ck-placeholder:before {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }


    .vConnectMainWrap.rightSideOnly .welcomeScreen .collapsibleChat {
        background-color: transparent !important;
        border-top: 0px !important;
    }

    .employeeSelectWrap {
        display: flex;
        align-items: center;

        .messagesArea-header_back {
            padding: 0;
            border-radius: 1000%;
            font-size: 20px;
            width: 20px;
            height: 32px;
            text-align: center;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            margin-right: 7px;
        }

        div {
            width: 100% !important;
        }

        .select2 {
            width: 100% !important;
        }
    }

    #note1,
    #Ghippy1,
    #Vagaro_Links1,
    #Vagaro_files1,
    #Emoji1 {
        display: none !important;
    }

    .mobileListmenuWrap {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }


    .messagesEditWrap .mobileListmenuWrap {
        display: block;
        position: relative;
        z-index: 2;
        
    }

    .messagesBox.messagesEditWrap .typeMsgText { position: relative; z-index: 2; }

    .vConnectMainWrap.rightSideOnly .collapsibleChat .ck-reset.ck-editor .ck-editor__main .ck-editor__editable p {
        line-height: 18px;
        margin: 0;
        padding-top: 9px;
        padding-bottom: 9px;
        word-wrap: break-word;
        word-break: break-word;
    }

    .mobileListmenu .dropdown-menu {
        position: fixed !important;
        width: 100%;
        transition: all .2s !important;
        opacity: 0 !important;
        visibility: hidden !important;
        display: block !important;
        border-radius: 0px;
        inset: auto 0 -100% 0 !important;
        background-color: #f8f8f8 !important;
        border: none !important;
        border-radius: 0 !important;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        padding: 16px;
    }

    .mobileListmenu.dropdown.show .dropdown-menu {
        transform: translateY(0) !important;
        visibility: visible !important;
        opacity: 1 !important;
        display: block;
        inset: auto 0 0% 0 !important;
    }

    .mobile-overlay-black {
        display: none;
        background: rgba(0, 0, 0, 0.25);
        z-index: 0;
        position: fixed;
        top: 0;
        bottom: 0px;
        right: 0px;
        left: 0px;
    }

    .mobileListmenu.show .mobile-overlay-black {
        display: block !important;
    }

    .mobileListmenu a {
        font-size: 16px;
        font-weight: 500;
        padding: 12px 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        background-color: #fff;
        border-top: solid #e7e7e7 1px;
    }

    .mobileListmenu a:first-of-type {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }

    .mobileListmenu a:last-of-type {
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .mobileListmenu .dropdown-toggle {
        padding: 0px;
        width: 32px;
        height: 32px;
        font-size: 24px;
        font-weight: 900;
        color: #DE3A3A;
        font-family: "Font Awesome 6 Pro";
        margin-left: 5px;
        z-index: 1;
        position: relative;
        margin-right: -10px;
        top: 12px;
        bottom: 0;
        border: none;
        outline: none;

        &:hover,
        &:focus,
        &:active {
            padding: 0;
            border: none;
            outline: none;
        }
    }

    .mobileListmenu a:first-of-type {
        border-radius: 0px;
        border-top: 0px;
    }

    .mobileListmenu a .fa-regular {
        width: 32px;
        height: 32px;
        text-align: center;
        margin-right: 4px;
        font-size: 20px;
        line-height: 32px;
    }

    .messagesArea-header,
    .right-mobileHeaderDetailBlock,
    .rightSideOnly-nav {
        position: sticky;
        top: 0;
        z-index: 4;
        background: #fff;
    }

    .chatListBlock-searchWrap {
        // position: sticky;
        z-index: 2;
        // top: 98px;
        background: #fff;
    }

    .vConnectMainWrap.rightSideOnly.chatActive.detailsActive .rightSlide {
        z-index: 99;
        position: absolute;
        left: 0;
        top: 0px;
        right: 0;
        border: 0px;
    }

    .chatList {
        flex-grow: 1;
        // overflow: auto;
    }

    .vConnectMainWrap .rightside-wrapper {
        display: flex;
        flex-direction: column;
    }

    .detail-content-scroll {
        height: 100%;
        overflow: auto;
    }

    .vConnectMainWrap .rightside-wrapper .bottom-content-wrap {
        height: auto !important;
    }

    .member-list-wrapper {
        height: 100%;
        overflow: auto;
    }

    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-middle {
        flex-grow: 1;
        height: auto;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .vConnectMainWrap.rightSideOnly .messagesArea-middle {

        padding-top: 0px;
        padding-bottom: 0px;
    }

    .vConnectMainWrap.rightSideOnly .messagesArea-middle .chat-middle-box {
        padding-top: 17px;
        padding-bottom: 17px;
        width: 100%;
        height: 100%;
    }

    .welcomeScreen-Blank .employeeSelectWrap {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .welcomeScreen-Blank .employeeSelectWrap .chat-middle-box {
        padding-top: 17px;
        padding-bottom: 17px;
        width: 100%;
    }

    .vConnectMainWrap.rightSideOnly.chatActive .collapsibleChat {
        position: sticky;
        z-index: 0;
        // &.textmarketing-composerwrap {
        //     padding-top: 25px !important;
        // }
    }

    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea.flex-fill {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        // &.islongPress {
        //     .messagesArea-footer {
        //         display: none;
        //     }
        // }
    }

    .vConnectMainWrap.rightSideOnly .chatarea .chatListBlock .chatList-groupBlock {
        // height: auto !important;
        overflow: auto;
    }

    .vConnectMainWrap.rightSideOnly.chatActive.detailsActive .rightside-wrapper {
        height: 100vh !important;
        border: 0px;
    }

    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-header_back {
        min-width: 44px;
        height: 44px;
        align-items: center;
        text-align: center;
        display: grid;
        margin-right: 0;
        text-decoration: none;
    }

    .vConnectMainWrap .chatarea .messagesArea-header {
        border: 0px !important;
        border-bottom: solid 1px #cdcdcd !important;
        min-height: 44px;
        padding: 0;
    }

    .vConnectMainWrap.rightSideOnly .chatarea .messagesArea-header_moreVertical .dropdown-toggle {
        width: 44px;
        height: 44px;
        align-items: center;
        text-align: center;
        display: grid;
        margin-right: 0;
    }

    .vConnectMainWrap.rightSideOnly .collapsibleChat,
    .vConnectMainWrap.rightSideOnly .upgradeBlockWrap {
        border: 0px !important;

    }

    .messagesArea-header_avGroup #messagesAreaGroupDetail {
        display: flex;
        align-items: center;
        width: auto;

        [data-avatar="1"] {
            .avatar-group-img-wrap {
                height: 27px;
                width: 27px;
            }
        }

        [data-avatar="3"] {
            .avatar-group-img-wrap:first-child {
                height: 26px;
                width: 26px;
            }
        }

        [data-avatar="4"] {
            .avatar-group-img-wrap-text {
                font-size: 7px;
                line-height: 12px;
            }
        }
    }

    .vConnectMainWrap .rightside-wrapper .right-mobileHeader-detail {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        flex-grow: 1;
        text-align: center;
        margin-right: 44px;
    }

    .vConnectMainWrap .rightside-wrapper .right-mobileHeader-detail.m-right-none {
        margin-right: 0;
    }

    .vConnectMainWrap .vcDrop-wrap.linkWrap .vcDrop-group {
        max-height: 80vh;
    }

    .vConnectMainWrap .vcDrop-wrap.linkWrap .vcDrop-group.dropdown-menu[x-placement=bottom-start] {
        top: auto !important;
        bottom: 0;
    }

    .iconchat {
        height: 32px;
        width: 32px;
        position: absolute;
        right: 20px;
        text-align: center;
        bottom: -1px;
        transform: translateY(-50%);
        line-height: 32px;
        color: #FF9F08;
        font-size: 20px;
        display: block;
    }

    .msg-header {
        height: 44px;
        background: var(--bkg_neutral_default);
        color: var(--text_neutral_default);
        border-bottom: solid #cdcdcd 1px;
        display: flex;
        width: 100%;
        align-content: center;
        align-items: center;
        justify-content: center;
    }

    .icon-width {
        height: 40px;
        text-align: center;
        width: 40px;
        min-width: 40px;
        align-items: center;
        display: flex;
        text-align: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 400;
    }

    .msg-title {
        font-size: 18px;
        font-weight: 500;
        color: var(--text_neutral_default);
        text-align: center;
        flex-grow: 1;
        // margin-right: 44px;
    }

    .msg-header {
        .icon-width~.msg-title {
            margin-right: 44px;
        }
    }

    .new-chat-text {
        margin-right: 44px;
    }

    .vConnectMainWrap.rightSideOnly .chatarea .chatListBlock-searchWrap .btn-filter {
        display: none !important;
    }

    // .vConnectMainWrap .chatList-person:active .chatList-person_dotMenu,
    // .vConnectMainWrap .chatList-person:hover .chatList-person_dotMenu {
    //     display: none !important;
    // }

    .vConnectMainWrap.rightSideOnly .chatListBlock .chatList-header {
        display: flex;
        position: sticky;
        top: 0px;
        background: var(--bkg_neutral_default);
        z-index: 2;
    }

    .mobile-contextmenu.dropdown .dropdown-menu {
        position: fixed !important;
        width: 100%;
        transition: all .2s !important;
        opacity: 0 !important;
        visibility: hidden !important;
        display: block !important;
        border-radius: 0px !important;
        inset: auto 0 -100% 0 !important;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
        border: none !important;
        bottom: 0 !important;
        top: auto !important;
        padding: 16px 16px 16px 16px;
        background-color: #f8f8f8 !important;
        transform: translateY(100%) !important;
        z-index: 99;


    }

    .vConnectMainWrap .rightside-wrapper .member-list-wrapper .chatList-person_dotMenu .dropdown-menu {
        padding: 16px 16px 16px 16px;
        background-color: #f8f8f8 !important;
        box-shadow: none !important;
    }

    .mobile-contextmenu.dropdown .dropdown-item {
        color: #333;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 12px 16px;
        border-bottom: solid 1px #e7e7e7;
        // gap: 10px;
        display: flex;
        align-items: center;
        background-color: #fff;

        i {
            margin-right: 4px;
            font-size: 20px;
            display: inline-block;
        }
    }

    .mobile-contextmenu.dropdown .dropdown-item:first-of-type {
        border-top-left-radius: 10px !important;
        border-top-right-radius: 10px !important;
    }

    .mobile-contextmenu.dropdown .dropdown-item:last-of-type {
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }

    .mobile-contextmenu.dropdown .dropdown-item .fa-regular,
    .mobile-contextmenu.dropdown .dropdown-item .fa-list,
    .mobile-contextmenu.dropdown .dropdown-item i {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
    }

    .mobile-contextmenu.dropdown.show .dropdown-menu {
        transform: translateY(0) !important;
        visibility: visible !important;
        opacity: 1 !important;
        display: block;
        inset: auto 0 0% 0 !important;
    }

    .mobile-contextmenu.dropdown.show:after {
        position: fixed;
        top: 0;
        background: rgba(24, 19, 43, 0.21);
        content: '';
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 3;
    }

    .vConnectMainWrap .chatarea .chatListBlock .chatList-person:hover .chatList-person_time {
        opacity: 1;
        display: block;
    }


    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-middle .messagesBox-block {
        max-width: 86%;
    }

    .vConnectMainWrap.rightSideOnly .chatarea .chatListBlock-searchWrap .searchFieldIcon {
        right: 10px;
    }

    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-middle .messagesBox.messagesEditWrap .messagesBox-block {
        max-width: 85%;
    }


    .mobile-contextmenu.dropdown .dropdown-item:last-of-type {
        border-bottom: 0px;
    }

    .add-member-dialogue .modal-footer .btn {
        font-size: 20px;
    }

    .ddlEmployee.select2-container.select2-container--default.vcEmployeeSelect,
    .ddlSelectCustomer1.select2-container.select2-container--default.vcEmployeeSelect {
        top: 100px !important;
    }

    .employee-multilocation-select #div-select2-results-count {
        display: none !important;
    }

    .employeeSelectWrap div {
        opacity: 0;
        visibility: hidden;
    }

    .ddlSelectCustomer1.select2-container.select2-container--default.vcEmployeeSelect .select2-search--dropdown,
    .select2-results {
        border-left: 0px !important;
        border-right: 0px !important;
        border-top: 0px !important;
    }

    //select2 css
    .employee-multilocation-select.select2-container {
        position: fixed !important;
        left: 0px !important;
        right: 0px !important;
        top: 0;
        height: 100% !important;
        bottom: 0px !important;
        z-index: 9999999;

        .select2-dropdown {
            width: 100% !important;
            height: 100%;
            min-width: auto !important;
            border: 0px !important;
            margin-top: 54px;
            top: 0px !important;
            box-shadow: none !important;
        }

        .select2-search--dropdown {
            padding: 12px 16px !important;
            position: fixed;
            border-bottom: solid #E7E7E7 1px;
            left: 0;
            top: 100px;
            right: 0px;
            z-index: 1;
            margin-bottom: 0px !important;
            height: auto !important;
            background: #fff;

            &:after {
                color: rgb(154, 154, 154) !important;
                content: "" !important;
                font-family: 'Font Awesome\ 6 Pro' !important;
                font-weight: 400 !important;
                left: 26px !important;
                position: absolute;
                top: 50% !important;
                transform: translateY(-50%) !important;
                display: inline-block !important;
                right: auto !important;
            }
        }
    }

    .employee-multilocation-select.select2-container .select2-dropdown .select2-search--dropdown .select2-search__field {
        background-color: #E7E7E7 !important;
        border: 0;
        border-radius: 8px;
        font-size: 16px;
        height: auto;
        padding: 8px 33px !important;
        position: relative;
    }

    .employee-multilocation-select.select2-container .select2-dropdown .select2-selectAll--dropdown {
        margin-top: 66px;
        background-color: #fff !important;
        border-bottom: solid #E7E7E7 1px !important;
        padding: 12px 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        display: none;
    }

    .employee-multilocation-select .select2-results {
        margin: 0 !important;
        margin-top: 4px !important;
        max-height: none !important;
        width: 100% !important;
        padding: 0 !important;
        box-shadow: none !important;
        border: 0px !important;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 159px) !important;
    }

    .employee-multilocation-select .select2-results ul {
        padding: 0px;
        margin: 0px;
        max-height: 100%;
        flex-grow: 1;
    }

    .employee-multilocation-select .select2-results ul li {
        padding: 12px 16px 12px 16px !important;
        border-bottom: solid #E7E7E7 1px;
        font-size: 16px;
        font-weight: 500 !important;
        line-height: 20px;
        color: #333 !important;
        position: relative;
    }

    .employee-multilocation-select .select2-results ul li ul {
        margin-left: 0;
        margin-right: 0;
    }

    .employee-multilocation-select.select2-container .select2-results ul li ul li.select2-results__option {
        border-top: solid #E7E7E7 1px;
        border-bottom: 0px;
        color: #333 !important;
        padding-left: 30px;
    }

    .employee-multilocation-select.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option[aria-selected=true] {
        &:after {
            content: "\f00c";
            font-family: "Font Awesome 6 Pro";
            font-weight: 400;
            right: 26px;
            left: inherit;
            position: absolute;
            top: 50%;
            // color: #EB6663;
            transform: translateY(-50%);
            background: var(--Connect-Primary-Button-Default, linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .employee-multilocation-select.select2-container--default .select2-results__group {
        color: #333 !important;
        font-size: 16px;
        font-weight: 500 !important;
        line-height: 20px;
        padding: 12px 16px 12px 16px;
    }

    .employee-multilocation-select.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option .multicheck-checkbox {
        position: absolute;
        left: inherit;
        top: 20px !important;
        right: 20px !important;
        border: 0px !important;
        outline: 0px !important;
    }

    .employee-multilocation-select.select2-container .select2-results__option[aria-selected=true] .multicheck-checkbox {
        color: #EB6663;
    }

    .employee-multilocation-select.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option[aria-selected=true] .multicheck-checkbox {
        border: 0px !important;
        background-color: transparent !important;
    }

    .employee-multilocation-select .labelpadd {
        padding: 0;
        font-weight: 500 !important;
    }

    .select2-selectAll--dropdown a:hover {
        color: #333;
    }

    .labelpadd.multilocation-business-label {
        padding-left: 10px;
        margin-left: 0px !important;
    }

    .select2-container--default .select2-results__option[aria-selected=true] .multicheck-checkbox:after {
        display: none;
    }

    .select2-container.select2-container--default .select2-dropdown .select2-results .select2-results__options .select2-results__option[aria-selected=false] .multicheck-checkbox {
        background: transparent !important;
        border: 0px !important;
    }

    .btn-select-done {
        background: var(--bkg_green_default);
        border-radius: 0.188rem;
        margin: 12px 16px;
        padding: 9px;
        text-align: center;
        color: #fff;
        height: 50px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 18px;
        text-decoration: none;
    }

    .select2-container.select2-container--default.vcEmployeeSelect.ddlSelectCustomer1 .select2-results__options {
        max-height: 100% !important;
    }

    .btn-select-done:hover {
        background: var(--bkg_components_button_green_hover);
    }

    .user-title {
        align-items: center;
        color: #333;
        display: flex;
        font-size: 18px;
        font-weight: 500;
        justify-content: center;
        margin-right: 40px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        text-transform: capitalize;
        white-space: nowrap;
        flex-grow: 1;
        gap: 10px;

        .avatar-md {
            width: 28px;
            height: 28px;
            flex: none;

            .avatar-group-img-wrap {
                width: 28px;
                height: 28px;
                line-height: 28px;
            }
        }
    }

    .GroupddEmployee.employee-multilocation-select {
        top: 44px !important;
    }

    .GroupddEmployee.employee-multilocation-select.select2-container .select2-search--dropdown {
        top: 44px !important;
    }

    .GroupddEmployee.employee-multilocation-select .multicheck-checkbox.defultselected {
        opacity: 0 !important
    }

    .employee-multilocation-select.select2-container.select2-container--default.select2-container--open.vcEmployeeSelect.GroupddEmployee .select2-results__options {
        max-height: 100% !important;
    }

    .mobile-fullscreen-pop {
        align-items: flex-start;

        .modal-dialog {
            width: 100%;
            max-width: 100%;
            height: 100%;
            margin: 0px;
            transform: none;
            transition: none;
        }

        .modal-title {
            justify-content: center;
            font-size: 18px;
            font-weight: 500;
        }

        .modal-content {
            margin-top: 0px;
            box-shadow: none;
            border-radius: 0;
            height: 100%;
            border: 0px !important;
        }

        .modal-header {
            padding: 0;
            border-bottom: solid #cdcdcd 1px;
            justify-content: center;
            height: 44px;
        }

        .modal-footer {
            background-color: transparent !important;
        }

        .btn-close {
            height: 44px;
            left: 0;

            margin: 0;
            right: auto !important;
            top: 0 !important;
            width: 44px;
            align-items: center;
            display: flex;
            justify-content: center;
        }


        .btn-close:after {
            content: '\f104' !important;
            font-weight: 900;
            font-size: 15px;
            color: #333 !important;
        }

        .btn-secondary {
            display: none;
        }

        .btn-primary {
            width: 100%;
            height: 50px;
        }
    }

    .right-mobileHeaderDetailBlock {
        #divAddMember {
            .fa-regular.fa-user-plus {
                font-size: 18px;
                color: var(--icon_neutral_default);
            }
        }

        .right-icon {
            min-width: 44px;
            text-align: center;
        }
    }

    .employee-multilocation-select.GroupddEmployee .select2-results {
        height: calc(100vh - 235px) !important
    }

    .mobile-fullscreen-pop .chat-history-box {
        position: fixed;
        bottom: 96px;
        left: 15px;
    }

    .GroupddEmployee.employee-multilocation-select.select2-container .select2-dropdown {
        top: 0px;
    }

    .GroupddEmployee.employee-multilocation-select.select2-container {
        height: auto !important;
        bottom: 120px !important;
        ;
    }

    .GroupddEmployee.employee-multilocation-select.select2-container .select2-dropdown {
        height: auto !important;
    }

    .m-content-hide {
        opacity: 0;
        visibility: hidden;
    }

    .vConnectMainWrap .rightside-wrapper .right-mobileHeader-back {
        padding: 0;
        min-width: 44px;
        height: 44px;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-content: center;
        align-items: center;
        text-decoration: none;
        color: #333;
    }

    .vcMobile .right-mobileHeaderDetailBlock .right-icon {
        padding: 0;
        min-width: 44px;
        height: 44px;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-header_name {
        max-width: 240px;
    }

    .vConnectMainWrap .messagesArea-header .avatar-group-img-wrap {
        left: 0;
        top: 0;
    }

    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea {
        height: auto;
    }

    .stencilBlock-messagesArea {
        height: auto;
    }

    .stencilBlock-messagesArea {
        height: calc(100vh - 104px) !important;
        overflow: hidden;
    }

    .customerChatWindow-mobile .vConnectMainWrap .smallChatBox-middle_nameArea {
        width: 90% !important;
    }

    .vConnectMainWrap .avatar[data-avatar="1"] .review-profile-inner-text {
        font-size: 12px;
    }

    .newchat-title {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .select2-container.select2-container--default.vcEmployeeSelect .select2-results__options .select2-results__option[aria-selected=true] .labelpadd {
        color: var(--text_neutral_default) !important;
    }

    .select2-container.select2-container--default.vcEmployeeSelect .select2-results__option:has(>span.multicheck-checkbox.defultselected:not(.multilocation-business-item)) .multicheck-checkbox.defultselected~label {
        color: var(--text_neutral_default) !important;
    }

    .vConnectMainWrap .EmojiPickerReact {
        width: 85% !important;
        max-width: 370px !important;
    }

    .vConnectMainWrap.rightSideOnly.chatActive .messagesArea-header_avGroup .avatar[data-avatar="1"] .avatar-group-img-wrap img {
        width: 27px;
        height: 27px;
    }

    .vConnectMainWrap .avatar-group-img-wrap div .review-profile-inner-text {
        font-size: 7px;
        padding-top: 0px;
        line-height: 9px;
    }

    .vConnectMainWrap .welcomeScreen-block {
        top: 0px;
        height: calc(100vh - 90px);
        padding-bottom: 26px;
    }

    .select2-container.select2-container--default.vcEmployeeSelect .select2-dropdown--below {
        // border-top: 1px solid #d9d9d9 !important;
    }

    .vConnectMainWrap .rightside-wrapper {
        height: 100vh;
    }


}

.mobilefull-height {
    height: 100%;
}

.mobilefull-height body,
.mobilefull-height .Reactinclude {
    height: 100%;
}

//css only for iso when keyword open
html.iosmobiledevice {
    height: 100%;
    scroll-behavior: smooth;
}

.iosmobiledevice .vcMobile.keywordopen {
    position: fixed;
    height: 100%;
    overflow: hidden;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}

.iosmobiledevice .vcMobile.keywordopen {
    .chatList {
        flex-grow: unset;
    }

    .employee-multilocation-select .select2-results ul {
        flex-grow: unset;
    }

    .bottom-content-wrap {
        height: auto;
    }

    .messagesArea-middle {
        flex-grow: initial;
    }
}

// .iosmobiledevice .vcMobile.keywordopen .vConnectMainWrap.rightSideOnly .welcomeScreen {
//     height: auto;
// }

.vcMobile,
.vcTab {    
    .alert-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
    .restricted-alert {
        color: var(--text_alert_default);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}
.vcMobile {
    background: transparent;
}

/*- Bhavesh Gohil -*/
/*- START: new theme phase 2 -*/
.pinnedBlockWrap,
.mobileHeaderNew,
.vConnectMainWrap .chatarea .chatListBlock-searchBlock .serachGrooup .mobileCancel {
    display: none;
}
@mixin removeSelection {
    // -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.vcMobile,
.vcTab {
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox {
        .reactedWrap {
            border-radius: 15px;
        }
    }
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox {
        .messages,
        .reactedWrap,
        .name,
        .time,
        .inChatmsg,
        &:not(.messagesEditWrap) .messages,
        .messages :not(.editControlmainWrap),
        .avatar {            
            @include removeSelection;
        }
    }
    .reactionPopHover {
        @include removeSelection;
    }
}
.vcMobile:not(.vcTab-portrait) .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox .editControlWrap .editControl-primary .editControl {
    @include removeSelection;
}



@media only screen and (max-width: 767px) {
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox {
        .messages,
        .reactedWrap,
        .name,
        .time,
        .inChatmsg,
        &:not(.messagesEditWrap) .messages,
        .avatar {
            @include removeSelection;
        }
    }
}
/*- START: chat gradiant --*/
@mixin chatAreaGradiant {
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages {
        background: #000 !important;
        color: #fff !important;
    }

    @supports(mix-blend-mode: screen) {
        .messagesArea-middle:after {
            content: '';
            position: absolute;
            right: 6px;
            top: 45px;
            bottom: 60px;
            left: 0;
            right: 0;

            background: var(--Gradient-Linear, linear-gradient(180deg, #0A6A9F 0%, #533C89 100%));
            // mix-blend-mode: screen;
            // background: url(https://1a96a36bae7c8550901a-274b8a70320bb26e7a1e0ea7836ee429.ssl.cf2.rackcdn.com/Images/chatsBackground.png);
            mix-blend-mode: screen;
            pointer-events: none;
            z-index: 1;
            // background-size: cover;
            // background-position: center center;
        }

        .vConnectMainWrap {
            .chatarea {
                .messagesArea-middle {

                    // .recived.messagesBox,
                    .inChatmsg,
                    .messagesBox.sent .name,
                    .messagesBox.sent .time,
                    .messagesBox.sent .avatar,
                    .messagesBox.sent .messages p,
                    .messagesBox.sent .messages p a,
                    .messagesBox.sent .messages p img,
                    .messagesBox.sent .messages a,
                    .messagesBox.sent .messages .mobileURlLink,
                    .chat-deletedWrap,
                    .messagesBox.sent .image-wrap.multiple,
                    .messagesBox.sent .image-wrap,
                    .messagesBox.recived .avatar,
                    .messagesBox.recived .messagesBox-block .name,
                    .messagesBox.recived .messagesBox-block .messages,
                    .messagesBox.recived .messagesBox-block .reactedWrap,
                    .messagesBox.recived .time {
                        position: relative;
                        z-index: 2;
                    }
                }

                .messagesArea-footer {
                    position: relative;
                    z-index: 4;

                    &:has(.mobileActiveChat) {
                        z-index: 9;
                    }
                }

                // .messagesBox.sent .messages.message-note-block {
                //     z-index: 3;
                // }

                // .messagesBox.sent .messages.message-note-block:has(.editControlWrap) {
                //     z-index: 5;
                // }

                .messagesArea-header {
                    position: relative;
                    z-index: 5;
                }
            }
        }
    }
}

/*- END: chat gradiant --*/
.vcMobile:not(.vcTab-portrait) {

    .ck-editor.ck-reset .ck-toolbar .cke_button.cke_button_Send {
        background: var(--Connect-Primary-Button-Default, linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%));
        padding: 0 9px 0 8px !important;
        &::before {
            font-size: 24px !important;
        }
    }
}

/*-START: Slide Conversation -*/
.vcMobile {
    .vConnectMainWrap {
        .chatarea {
            .chatListBlock {
                .chatList-groupBlock{
                    .swipeable-list-item__leading-actions,
                    .swipeable-list-item__trailing-actions {
                        border-bottom: 1px solid #cdcdcd;
                        .slideButton {
                            // flex: 0 0 72px;
                            align-items: center;
                            background: var(--bkg_neutral_secondary);
                            padding: 8px;
                            color: var(--text_neutral_default);
                            &-wrap {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                            }
                            &-icon {
                                color: var(--text_neutral_default);
                                text-align: center;
                                font-family: "Font Awesome 5 Free";
                                font-size: 26px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-bottom: 4px;
                            }
                            &-text {
                                color: var(--text_neutral_default);
                                text-align: center;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 16px;
                            }

                            &-unread {
                                background: #0A6A9F;
                                color: var(--text_neutral_ondark);
                                .slideButton-text,
                                .slideButton-icon {
                                    color: var(--text_neutral_ondark);
                                }
                            }
                            &-delete {
                                background: #D83F34;
                                color: var(--text_neutral_ondark);
                                .slideButton-text,
                                .slideButton-icon {
                                    color: var(--text_neutral_ondark);
                                }
                            }
                            &-pinChat {}
                            &-more {}
                        }
                    }
                    .slideButtonMoreDeleteWrap {
                        display: flex;
                    }
                }
            }
        }
    }
}
/*-END: Slide Conversation -*/

.vConnectMainWrap .ck-reset.ck-editor.textmarketing-alert:not(.note-active) {
    .cke_button.cke_button_Send {
        pointer-events: none;
        // opacity: 0.5;
    }
}

.vcMobile {
    .vConnectMainWrap .rightside .rightside-wrapper .contact-wrap.addNumberBlock a {
        margin-bottom: 6px !important;
    }
    .vConnectMainWrap .rightside-wrapper .services-list-wrap.upcoming-appt-wrap .appointment-detail .bottom-icons {
        a {
            pointer-events: none;
        }
    }
    .vConnectMainWrap .image-wrap .imgBlock {
        height: auto;
    }
    @include chatAreaGradiant;

    .vConnectMainWrap.rightSideOnly .collapsibleChat .ck-reset.ck-editor {
        &.textmarketing-alert {
            .ck-editor__main {
                border: 1px solid var(--border_alert_default) !important;
                background: #FBECEB !important;
            }
        }
        &.restrictedText-alert {
            .ck-editor__main {
                border: 1px solid var(--border_alert_default) !important;
                background: #FBECEB !important;
            }
        }
    }

    .vConnectMainWrap.rightSideOnly .chatarea .chatListBlock .chatList-groupBlock {
        height: calc(100vh - 160px);
    }

    .pinnedBlockWrap {
        overflow-x: auto;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        padding-left: 16px;
        margin-top: 8px;
        margin-bottom: 8px;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }

        .pinnedBlock {
            margin: 8px 7px;

            .avatar.avatar-md {
                height: 60px;
                width: 60px;
                flex: 0 0 60px;
                border-radius: 30px;

                .avatar-group-img-wrap {
                    height: 60px;
                    width: 60px;

                    .review-profile-inner-text {
                        font-size: 14px;
                    }
                }
            }

            .pinnedName {
                color: #000;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                margin-top: 4px;
                white-space: nowrap;
                overflow: hidden;
                max-width: 60px;
                text-overflow: ellipsis;
                text-align: center;
            }
        }
    }

    .chatList-group .accordion-header {
        display: none;
    }

    .pinnedGroupMobile {
        .chatList-person {
            &_overlay {
                display: none;
            }

            .chatList-person_dotMenu,
            .chatList-person_right-bottom,
            .chatList-person_time {
                display: none !important;
            }

            .userBlock-icon {
                left: 9px !important;
                top: 4px !important;
            }

            .status {
                &.unread {
                    z-index: 9;
                    right: 5px !important;
                    top: 4px;
                    display: none;
                }
            }
        }

        .accordion-body {
            overflow-x: auto;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            padding-left: 16px !important;
            padding-right: 10px !important;
            margin-top: 0px;
            margin-bottom: 0px;

            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;

            /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }

            .avatar {
                margin-right: 0 !important;
            }

            .chatList-person {
                display: flex;
                flex-direction: column;
                border-bottom: none !important;
                margin: 8px 2px;
                padding: 0 !important;
                max-width: 69px;
                width: 69px;
                flex: 0 0 69px;

                &_right {
                    width: 100% !important;
                }
            }

            .chatList-person_right {
                &-top {
                    justify-content: center !important;
                }
            }

            .chatList-person_name {
                max-width: 100% !important;
                color: #000 !important;
                text-align: center;
                font-size: 12px !important;
                font-style: normal !important;
                font-weight: 800 !important;
                line-height: 16px !important;
                margin-top: 4px;
                padding-right: 0 !important;

            }


        }
    }

    // Conversation.jsx 2067
    .mobileHeaderNew {
        padding: 8px 16px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-textArea {
            color: var(--text_neutral_default);
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
        }

        &-btnArea {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .filterMobile {
                background: none;
                border: none;
                outline: none;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                line-height: 24px;
                padding: 0;
                border-radius: 50%;
                flex: 0 0 24px;
                height: 24px;
                width: 24px;
                color: #000;

                &-active {
                    background: var(--Gradient-Linear, linear-gradient(180deg, #0a6a9f 0, #533c89 100%));
                    color: #fff;


                }
            }

            .dropdown {
                margin: 0 8px;
            }

            .dropdown,
            .dropdown:hover,
            .dropdown:focus {
                border: none;
                outline: none;
                box-shadow: none;

                .dropdown-toggle {
                    padding: 0;
                    border: none;
                    outline: none;
                    box-shadow: none;
                }

            }

            &_newChat {
                border-radius: 24px;
                background: var(--Gradient-Linear, linear-gradient(180deg, #0A6A9F 0%, #533C89 100%));
                border: none;
                outline: none;
                height: 24px;
                width: 24px;
                flex: 0 0 24px;
                color: var(--text_neutral_inverse);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 8px;
                font-size: 14px;
                line-height: 24px;
                padding: 0;
            }
        }
    }

    .vConnectMainWrap .chatarea .chatListBlock .chatList-person .userBlock-icon {
        top: 8px;
        left: 17px;
    }

    .vConnectMainWrap {
        .upgradeBlockWrap {
            padding: 16px;
            box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
            position: fixed !important;

            .upgradeBlock {
                border-radius: 8px;
                background: var(--Gradient-Diagonal, linear-gradient(149deg, #076A9F 8.76%, #533C89 103.94%)) !important;
                padding: 16px;
                margin-bottom: 0;
                display: block;

                .textArea {
                    &-title {
                        svg {
                            display: none;
                        }

                        color: var(--text_neutral_ondark) !important;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 24px;
                        margin-bottom: 4px;

                        .closeupgradeBlock {
                            position: absolute;
                            top: 30px;
                            right: 21px;

                            svg {
                                display: block;

                                path {
                                    fill: var(--icon_neutral_ondark) !important;
                                }
                            }
                        }
                    }
                }

                &-btnAreea {
                    margin-top: auto;
                    display: flex;
                    align-items: self-end;
                    justify-content: space-between;
                    flex-direction: row;
                    text-align: left;

                    .textArea-info {
                        color: var(--text_neutral_ondark) !important;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        max-width: 185px;
                    }

                    .upgradeBlock-btn {
                        border-radius: 56px;
                        border: 1px solid var(--border_components_button_default);
                        background: var(--bkg_neutral_default);
                        margin-left: 10px;

                        span {
                            text-align: center;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                            background: var(--Gradient-Diagonal, linear-gradient(149deg, #076A9F 8.76%, #533C89 103.94%));
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
            }
        }
    }

    .vConnectMainWrap.rightSideOnly .chatListBlock .chatList-header {
        display: none;
    }

    .vConnectMainWrap.rightSideOnly {
        .rightSideOnly-navBlock {
            border-radius: 24px;
            background: var(--bkg_neutral_tiertiary) !important;
            width: 270px;
            margin: 10px auto;
            padding: 2px;
            margin-top: 20px;

            .rightSideOnly-navBlock_option {
                color: var(--text_neutral_secondary);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                border: none;
                padding: 3px 7px;

                &.active {
                    color: var(--text_neutral_ondark) !important;
                    border-radius: 24px;
                    border: 0.5px solid rgba(0, 0, 0, 0.04);
                    background: var(--Gradient-Linear, linear-gradient(180deg, #0A6A9F 0%, #533C89 100%));
                    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12), 0px 3px 1px 0px rgba(0, 0, 0, 0.04);
                }

                // @media only screen and (min-width: 400px) {
                //     padding: 7px;
                // }
            }
        }

        .rightSideOnly-nav.inboxPage-header {
            .rightSideOnly-navBlock {
                margin: 10px auto;
            }
        }
    }

    /*- Search Bar -*/
    .chatListBlock-searchWrap {

        // padding-top: 0 !important;
        .btn-addToChat {
            display: none !important;
        }
    }

    .vConnectMainWrap .chatarea .chatListBlock-searchBlock .serachGrooup {
        .searchFieldIcon {
            color: var(--icon_neutral_weak);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            line-height: 16px;
            opacity: 1;
            // top: 10px;
        }

        input.searchField {
            border-radius: 24px;
            background: var(--bkg_neutral_tiertiary) !important;
            padding: 7px 30px;

            color: var(--text_neutral_default);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            &::placeholder {
                color: var(--text_neutral_secondary);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }

        .mobileCancel {
            display: none;
            color: var(--text_neutral_default);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin: 8px;
            text-decoration: none;
        }

        &.searchActive {
            .mobileCancel {
                display: block;
            }

            .searchFieldIcon {
                right: 76px;
                top: auto;
            }
        }
    }

    /*- Select2 search Bar -*/
    .select2-container.select2-container--default.vcEmployeeSelect .select2-search--dropdown:after {
        font-size: 14px;
    }

    .employee-multilocation-select.select2-container .select2-dropdown .select2-search--dropdown {
        display: flex;
        align-items: center;
        justify-content: center;

        .select2-search__field {
            border-radius: 24px;
            background: var(--bkg_neutral_tiertiary) !important;
            padding: 7px 30px;

            color: var(--text_neutral_default) !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            &::placeholder {
                color: var(--text_neutral_weak) !important;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
        }

        .vg_clear_search.show {
            height: 16px;
            width: 20px;
            position: absolute;
            right: 20px;

            &::after {
                content: '\f057';
                color: var(--icon_neutral_weak);
                text-align: center;
                font-family: "Font Awesome 6 Pro";
                font-size: 16px;
                font-style: normal;
                font-weight: 900;
                line-height: 16px;
            }
        }

        .mobileCancelSelect2 {
            display: none;
            color: var(--text_neutral_default);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin: 8px;
            text-decoration: none;
            border: none;
            background: none;
            padding: 0;
        }

        &.selectSearchActive {
            .mobileCancelSelect2 {
                display: block;
            }

            .vg_clear_search.show {
                right: 85px;
            }
        }

        &:after {
            color: var(--icon_neutral_weak) !important;
        }
    }

    /*- Select2 search Bar -*/
    /*- Search Bar -*/

    /*- START: conversation -*/
    .vConnectMainWrap {
        .chatarea {
            .chatListBlock {
                .chatList-person {
                    padding: 6px 12px;
                    width: 100%;

                    &.personRead .chatList-person_messages {
                        font-weight: 400;
                    }

                    .chatList-person_messages {
                        font-weight: 500;
                    }

                    &_left {

                        // margin-left: 0;
                        .avatar.avatar-md {
                            height: 60px;
                            width: 60px;
                            flex: 0 0 60px;
                            border-radius: 30px;

                            .avatar-group-img-wrap .review-profile-inner-text {
                                font-size: 12px;
                            }

                            // .avatar-group-img-wrap {
                            //     img {
                            //         height: 60px;
                            //         width: 60px;
                            //     }
                            // }
                            &[data-avatar="1"] {
                                .avatar-group-img-wrap {
                                    height: 61px;
                                    width: 61px;

                                    img {
                                        height: 60px;
                                        width: 60px;
                                    }

                                    .review-profile-inner-text {
                                        font-size: 18px;
                                    }
                                }
                            }
                            &[data-avatar="2"] {
                                .avatar-group-img-wrap {
                                    height: 61px;
                                    width: 50%;

                                    img {
                                        height: 60px;
                                        width: 60px;
                                    }
                                }
                            }

                            &[data-avatar="3"] {
                                .avatar-group-img-wrap:first-child {
                                    height: 61px;
                                    width: 61px;
                                }

                                .avatar-group-img-wrap img {
                                    height: 100%;
                                    width: 100%;
                                }
                            }

                            &[data-avatar="4"] {
                                .avatar-group-img-wrap-text {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 12px;
                                }
                            }
                        }
                    }

                    &_right {
                        width: 86%;
                    }

                    &_messages {
                        width: 95%;
                    }

                    .status {
                        position: absolute;
                        right: 10px;
                        bottom: 22px;
                        // display: none;
                    }

                }
            }
        }
    }
    /*- END: conversation -*/


    /*- Start: button theme -*/
    /*New button gradient design here*/

    .vcgradientbtn {
        padding: 16px !important;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        border-radius: 56px;
        width: 100%;
    }

    .vcprimary-btn {
        background: linear-gradient(148.99deg, #076A9F 8.76%, #533C89 103.94%) !important;
        border: none;
        color: #FFF!important;

        &:hover {
            background: linear-gradient(139.35deg, #076A9F 45.18%, #533C89 127.99%) !important;
            transition: 0.5s all ease;
        }

        &:focus,
        &:active {
            background: linear-gradient(144.78deg, #076A9F -45.07%, #533C89 67.63%) !important;
        }
    }

    .vcsecondary-btn {
        border-radius: 56px;
        background: #fff;
        border: none;
        padding: 0;

        // Rwmoved for ipad issues
        // &:hover {
        //     background: linear-gradient(148.99deg, #076a9f14 8.76%, #533c8914 103.94%);
        // }

        &:active,
        &:focus {
            background: linear-gradient(148.99deg, rgba(7, 106, 159, 0.2) 8.76%, rgba(83, 60, 137, 0.2) 103.94%);
        }

        .vcsecondaryInner {
            background: linear-gradient(180deg, #0A6A9F 0%, #533C89 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            color: transparent;
            border-radius: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            position: relative;
            z-index: 0;
            text-decoration: none;
            border: none;
            padding: 16px;

            &:before,
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                inset: 0;
                border: 2px solid transparent;
                border-radius: 56px;
                background: inherit;
                background-origin: border-box;
                background-clip: border-box;
                -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                -webkit-mask-repeat: no-repeat;
            }
        }
    }

    .vctertiary-btn {
        background: none;
        border: none;
        color: #076A9F;

        &:hover {
            background: #F3F8FA;
        }

        &:active,
        &:focus {
            background: #DCEAF2;
        }
    }

    .vctertiary-btn-sm {
        font-size: 16px;
    }

    .vdestructive-btn,
    .vdestructive-btn:hover {
        color: #C5392F;
        border: 2px solid #D83F34;
        background: #FFFFFF;

        &:active,
        &:focus {
            background: #FBECEB;
        }
    }

    .select2Done {
        margin: 12px 16px 24px 16px;
        justify-content: center;
        width: auto;
    }

    
    .modal-footer {
        .btn.btn-secondary.vcsecondary-btn,
        .btn.btn-secondary.vcsecondary-btn:hover {
            height: 50px;
            border: none !important;
            padding: 0 !important;
            .vcsecondaryInner {
                width: 100%;
            }
        }
        .btn.btn-primary.vcgradientbtn.vcprimary-btn {
            height: 50px;
        }
    }

    /*- End: button theme -*/
}

@media only screen and (max-width: 767px) {
    .vcMobile .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.messagesEditWrap .EmojiPickerReact { max-height: 350px; height: 350px;}
    .vcMobile em-emoji-picker { max-height: 350px; height: 350px;}
    .iosmobiledevice .vcMobile {
       .vConnectMainWrap.rightSideOnly .chatarea .chatListBlock .chatList-groupBlock {height: calc(100vh - 170px);}
       .vConnectMainWrap.rightSideOnly .chatarea .chatListBlock { height: 100%;}
    
    }
    }


.vcMobile {
    .add-member-dialogue .chat-history-box .check-label input:checked~.checkmark {
        background: var(--Connect-Primary-Button-Default, linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%));
    }
    .vConnectMainWrap .chatarea .messagesArea-middle .chat-deleted {
        border-radius: 15px;
    }

    // @media only screen and (min-width: 400px) {
    //     .employee-multilocation-select.select2-container .select2-dropdown .select2-search--dropdown .select2-search__field,
    //     input.searchField {
    //         padding: 10px 30px !important;
    //     }
    // }
    #messagesAreaGroupDetail {
        .avatar {
            &[data-avatar="2"] {
                .avatar-group-img-wrap {
                    height: 100%;
                    width: 50%;
    
                    img {
                        // height: 60px;
                        // width: 60px;
                    }
                }
            }
        }
    }
    .vConnectMainWrap .rightside-wrapper .carousel-control-next {
        right: -3px;
    }

    .vConnectMainWrap .rightside-wrapper .carousel-control-prev {
        left: -12px;
    }

    .vConnectMainWrap .rightside-wrapper .member-list-wrapper .name-wrap .right-icon {
        margin-top: 0px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail .contact-wrap a i.fa-regular.fa-phone-flip {
        &::before {
            content: '\f095';
        }
    }

    .businessLocationWrap {
        margin-top: 16px;
        padding-top: 16px;
    }

    .vConnectMainWrap .rightside-wrapper .contact-wrap a:last-child {
        margin-bottom: 0 !important;
    }

    .vagaroLinkWrap {
        border-radius: 20px;
        overflow: hidden;
    }

    .vConnectMainWrap .rightside-wrapper .contact-wrap a {
        margin-bottom: 8px !important;
    }

    .vConnectMainWrap .rightside-wrapper .member-list-wrapper .name-wrap h3 {
        font-size: 18px;
    }

    .vConnectMainWrap .rightside-wrapper .services-list-wrap {
        .block-status {
            padding-top: 4px;
        }
    }

    .vConnectMainWrap .welcomeScreen-block .start-chatting-btn {
        position: fixed;
    }

    //avatar changes
    // .vConnectMainWrap .rightside-wrapper .member-list-wrapper .profile-image {
    //     .avatar[data-avatar="3"] {
    //         .avatar-group-img-wrap {                
    //             &:nth-child(2) {
    //                 .review-profile-inner-text {
    //                     align-items: end;
    //                     justify-content: end;
    //                     padding: 8px;
    //                 }
    //             }
    //             &:nth-child(3) {
    //                 .review-profile-inner-text {
    //                     align-items: start;
    //                     justify-content: end;
    //                     padding: 8px;
    //                 }
    //             }
    //         }
    //     }
    //     .avatar[data-avatar="4"] {
    //         .avatar-group-img-wrap {                
    //             &:nth-child(1) {
    //                 .review-profile-inner-text {
    //                     align-items: end;
    //                     justify-content: end;
    //                     padding: 8px;
    //                 }
    //             }
    //             &:nth-child(2) {
    //                 .review-profile-inner-text {
    //                     align-items: end;
    //                     justify-content: start;
    //                     padding: 8px;
    //                 }
    //             }
    //             &:nth-child(3) {
    //                 .review-profile-inner-text {
    //                     align-items: start;
    //                     justify-content: end;
    //                     padding: 8px;
    //                 }
    //             }
    //             &:nth-child(4) {
    //                 .avatar-group-img-wrap-text {
    //                     align-items: start;
    //                     justify-content: start;
    //                     padding: 8px;
    //                 }
    //             }
    //         }
    //     }
    // }
    .vConnectMainWrap .chatarea .chatListBlock .chatList-noData_search {
        padding: 14px 10px 10px 10px;
    }
    .add-member-dialogue {
        .modal-body {
            padding-bottom: 0;
        }
        .modal-footer {
            border-top: none !important;
        }
        .chat-history-box {
            padding: 8px 16px;
            width: 100%;
            border-top: 1px solid var(--border_neutral_strong);
            border-bottom: 1px solid var(--border_neutral_strong);
            left: 0;
            bottom: 81px;
            .check-label {
                color: var(--text_neutral_default);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                padding-left: 32px;
                .checkmark {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }

    .select2-dropdown.select2-dropdown--below {
        .select2-search.select2-selectAll--dropdown{
            display: none;
            top: 0;
            border-radius: 10px;
            background: #fff;
            padding: 11px 16px;
            left: 16px;
            right: 16px;
            width: calc(100% - 32px);
            margin-top: 0;

        }
        .mobileCancelSelect2 {
            display: none;
        }
        .selectall.AllCustomer {
            overflow: hidden;
            color: var(--Labels_Primary);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    /*- START: Add member Mobile -*/
    .addMemberModal {
        height: 100%;
        .modal-dialog {
            .modal {
                &-content {
                    margin-top: 0;
                    border: none !important;
                    border-radius: 0;
                    height: 100%;
                    box-shadow: none;
                }
                &-header {
                    padding: 16px 8px;
                    .btn-close {
                        // display: none;
                        left: 0;
                        right: auto;
                        height: 44px;
                        width: 44px;
                        top: 0px !important;
                        &::after {
                            content: '\f053';
                            color: var(--icon_neutral_default);
                            text-align: center;
                            font-family: "Font Awesome 6 Pro";
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 900;
                            line-height: normal;
                        }
                    }
                    .modal-title {
                        justify-content: center;
                        color: var(--text_neutral_default);
                        text-align: center;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }
                &-body {
                    height: 100%;
                    .m-content-hide {
                        opacity: 0;
                        visibility: hidden;
                    }
                    .chat-history-box {
                        position: absolute;
                        bottom: 0;
                        padding: 8px 16px;
                        left: 0;
                        width: 100%;
                        border-top: 1px solid var(--border_neutral_strong);
                        .check-label {
                            color: var(--text_neutral_default);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 26px;
                            padding-left: 32px;
                            .checkmark {
                                height: 24px;
                                width: 24px;
                            }
                        }
                    }
                }
                &-footer {
                    background: transparent !important;
                    border: none;
                    .btn.btn-secondary {
                        display: none;
                    }

                    .btn-primary.vcgradientbtn.vcprimary-btn {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    .vcEmployeeSelect.GroupddEmployee.employee-multilocation-select {
        // border: 1px solid #f00;
        top: 53px !important;
        height: calc(100vh - 185px);
        left: 0 !important;

        .select2-dropdown.select2-dropdown--below {
            width: 100% !important;
            padding: 0;
            background: var(--bkg_neutral_secondary) !important;
            // background: #fff !important;
            height: calc(100vh - 232px) !important;

            
            .select2-search.select2-search--dropdown {
                display: flex;
                align-items: center;
                height: auto;
                .select2-search__field {
                    background: var(--bkg_neutral_tiertiary) !important;
                    border-radius: 24px;
                    color: var(--text_neutral_default) !important;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 8px 33px;
                    height: auto;
                }
                
               
            }
            .select2-search.select2-selectAll--dropdown{
                top: 14px;
                border-radius: 10px;
                background: #fff;
                padding: 11px 16px;
                left: 16px;
                right: 16px;
                width: calc(100vw - 32px);
                border: none !important;
            }
            .selectall.AllCustomer {
                overflow: hidden;
                color: var(--Labels_Primary);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
            .select2-results {
                background: transparent !important;
                border: none !important;
                height: 100%;
                box-shadow: none;
                padding-top: 0px !important;
                .select2-results__options {
                    height: 100%;
                    min-height: 100%;
                    .select2-results__option {
                        border-bottom: 1px solid #e7e7e7;
                        color: #333 !important;
                        font-size: 16px;
                        font-weight: 500 !important;
                        line-height: 20px;
                        padding: 12px 16px !important;
                        position: relative;
                        .multicheck-checkbox {
                            display: none;
                        }
                        &.select2-results__option--highlighted {}

                        &[aria-selected=true]:after {
                            color: var(--ButtonBackColor) !important;
                            content: "";
                            font-family: 'Font Awesome\ 6 Pro';
                            font-weight: 400;
                            left: inherit;
                            position: absolute;
                            right: 26px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        .labelpadd {
                            padding: 0;
                            font-weight: 500;
                            margin-left: 0;
                            &.multilocation-business-label {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    /*- END: Add member Mobile -*/

}

.vcTab-portrait,
.vcTab {
    .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox-block .messages {
        border-radius: 15px;
    }
    .ck-editor.ck-reset {
        border-radius: 15px;
    }
    .ck-editor.ck-reset .ck-toolbar .cke_button.cke_button_Send {
        border-radius: 100px;
    }
    .vConnectMainWrap .avatar-group-img-wrap-text {
        font-weight: 400;
        font-size: 12px !important;
    }
    .vConnectMainWrap .chatarea .messagesArea-middle .chat-deleted {
        border-radius: 15px;
    }
}

.vcTab {
    .messagesEditWrap .mobileListmenuWrap {
        position: relative;
        z-index: 2;
        
    }
    .vConnectMainWrap .chatarea .messagesArea-header_avGroup {
        width: 95%;
    }
    .add-member-dialogue .chat-history-box .check-label input:checked~.checkmark {
        background: var(--Connect-Primary-Button-Default, linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%));
    }
    .pinnedGroupMobile {
        .chatList-person {
            &_overlay {
                display: none;
            }
        }
    }
    .vcgradientbtn {
        padding: 16px !important;
        font-size: 20px !important;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        border-radius: 56px;
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .vcprimary-btn {
        background: linear-gradient(148.99deg, #076A9F 8.76%, #533C89 103.94%) !important;
        border: none;
        color: #FFF;

        &:hover {
            background: linear-gradient(139.35deg, #076A9F 45.18%, #533C89 127.99%) !important;
            transition: 0.5s all ease;
        }

        &:focus,
        &:active {
            background: linear-gradient(144.78deg, #076A9F -45.07%, #533C89 67.63%) !important;
        }
    }

    .vcsecondary-btn {
        border-radius: 56px;
        background: #fff;
        border: none;
        padding: 0;

        &:hover {
            background: linear-gradient(148.99deg, #076a9f14 8.76%, #533c8914 103.94%);
        }

        &:active,
        &:focus {
            background: linear-gradient(148.99deg, rgba(7, 106, 159, 0.2) 8.76%, rgba(83, 60, 137, 0.2) 103.94%);
        }

        .vcsecondaryInner {
            background: linear-gradient(180deg, #0A6A9F 0%, #533C89 100%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            color: transparent;
            border-radius: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            position: relative;
            z-index: 0;
            text-decoration: none;
            border: none;
            padding: 16px;
            padding-left: 25px;
            padding-right: 25px;

            &:before,
            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                inset: 0;
                border: 2px solid transparent;
                border-radius: 56px;
                background: inherit;
                background-origin: border-box;
                background-clip: border-box;
                -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
                -webkit-mask-composite: xor;
                mask-composite: exclude;
                -webkit-mask-repeat: no-repeat;
            }
        }
    }

    .vctertiary-btn {
        background: none;
        border: none;
        color: #076A9F;

        &:hover {
            background: #F3F8FA;
        }

        &:active,
        &:focus {
            background: #DCEAF2;
        }
    }

    .vctertiary-btn-sm {
        font-size: 16px;
    }

    .vdestructive-btn,
    .vdestructive-btn:hover {
        color: #C5392F;
        border: 2px solid #D83F34;
        background: #FFFFFF;

        &:active,
        &:focus {
            background: #FBECEB;
        }
    }

    .modal-footer {
        .btn.btn-secondary.vcsecondary-btn,
        .btn.btn-secondary.vcsecondary-btn:hover {
            height: 50px;
            border: none !important;
            padding: 0 !important;
            .vcsecondaryInner {
                width: 100%;
            }
        }
        .btn.btn-primary.vcgradientbtn.vcprimary-btn {
            height: 50px;
        }
    }

    .vConnectMainWrap {
        .chatList-groupBlock {
            .accordion-body {
                .avatar {
                    &[data-avatar="2"] .avatar-group-img-wrap {
                        height: 100%;
                    }
                }
            }
        }
        .personal-info {
            .avatar {
                &[data-avatar="2"] .avatar-group-img-wrap {
                    height: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    /*- START: Add member -*/
    &.addTeamMateModalOpen {
        .fade.modal-backdrop.show {
            display: none !important;
        }
    }
    .addMemberModal {
        height: 100%;
        width: 392px;
        max-width: 392px;
        .modal-dialog {
            width: 392px;
            max-width: 392px;
            margin-left: 0;
            height: 100%;
            .modal {
                &-content {
                    margin-top: 0;
                    border: none !important;
                    border-radius: 0;
                    height: 100%;
                    box-shadow: none;
                }
                &-header {
                    padding: 16px 8px;
                    .btn-close {
                        // display: none;
                        left: 0;
                        right: auto;
                        height: 32px;
                        width: 32px;
                        top: 27px;
                        &::after {
                            content: '\f053';
                            color: var(--icon_neutral_default);
                            text-align: center;
                            font-family: "Font Awesome 6 Pro";
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 900;
                            line-height: normal;
                        }
                    }
                    .modal-title {
                        justify-content: center;
                        color: var(--text_neutral_default);
                        text-align: center;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }
                &-body {
                    height: 100%;
                    .m-content-hide {
                        opacity: 0;
                        visibility: hidden;
                    }
                    .chat-history-box {
                        position: absolute;
                        bottom: 0;
                        padding: 8px 16px;
                        left: 0;
                        width: 100%;
                        border-top: 1px solid var(--border_neutral_strong);
                        .check-label {
                            color: var(--text_neutral_default);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 23px;
                            padding-left: 32px;
                            .checkmark {
                                height: 24px;
                                width: 24px;
                            }
                        }
                    }
                }
                &-footer {
                    background: transparent !important;
                    border: none;
                    .btn.btn-secondary {
                        display: none;
                    }

                    .btn-primary.vcgradientbtn.vcprimary-btn {
                        font-size: 20px;
                        width: 100%;
                    }
                }
            }
        }

        &.modal.fade .modal-dialog {
            transform: translateY(0);
        }

        &.fade {    
            transform: translateX(-100%);
            // transform: translateY(0);
            opacity: 0;
            -webkit-transition: all .25s linear;
            -o-transition: all .25s linear;
            transition: all .25s linear;
    
            &.show {
                opacity: 1;
                transform: translateX(0);
                // transform: translateY(0);
            }
        }

    }
    .vcEmployeeSelect.GroupddEmployee.employee-multilocation-select {
        // border: 1px solid #f00;
        top: 56px !important;
        width: 392px;
        height: calc(100vh - 180px);
        left: 0 !important;

        .select2-dropdown.select2-dropdown--below {
            width: 100% !important;
            // padding: 16px 0 0 0;
            background: var(--bkg_neutral_secondary) !important;
            // background: #fff !important;
            height: 100%;
            
            
            .select2-search.select2-search--dropdown {
                position: relative;
                display: flex;
                align-items: center;
                height: auto;
                padding: 16px 16px 0 16px;
                .select2-search__field {
                    background: var(--bkg_neutral_tiertiary) !important;
                    border-radius: 24px;
                    color: var(--text_neutral_default) !important;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    padding: 8px 33px;
                    height: auto;
                }
                .vg_clear_search.show {
                    height: 16px;
                    position: absolute;
                    right: 22px;
                    width: 20px;
                    &:after {
                        color: var(--icon_neutral_weak);
                        content: "";
                        font-family: Font Awesome\ 6 Pro;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: 16px;
                        text-align: center;
                    }
                }
                &::after {
                    // display: none;
                    color: #9a9a9a !important;
                    content: "" !important;
                    display: inline-block !important;
                    font-family: Font Awesome\ 6 Pro !important;
                    font-weight: 400 !important;
                    left: 25px !important;
                    position: absolute;
                    right: auto !important;
                    top: auto !important;
                }
            }
            .select2-search.select2-selectAll--dropdown{
                display: none;
                top: 77px;
                border-radius: 10px;
                background: #fff;
                padding: 11px 16px;
                left: 16px;
                right: 16px;
                width: calc(100% - 32px);
            }
            .selectall.AllCustomer {
                overflow: hidden;
                color: var(--Labels_Primary);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
            }
            .select2-results {
                background: transparent !important;
                border: none !important;
                height: 100%;
                box-shadow: none;
                padding-top: 10px;
                height: calc(100% - 53px);
                .select2-results__options {
                    height: 100%;
                    min-height: 100%;
                    .select2-results__option {
                        border-bottom: 1px solid #e7e7e7;
                        color: #333 !important;
                        font-size: 16px;
                        font-weight: 500 !important;
                        line-height: 20px;
                        padding: 12px 16px !important;
                        position: relative;
                        .multicheck-checkbox {
                            display: none;
                        }
                        &.select2-results__option--highlighted {}

                        &[aria-selected=true]:after {
                            // color: var(--ButtonBackColor) !important;
                            background: var(--Connect-Primary-Button-Default, linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%));
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            content: "";
                            font-family: 'Font Awesome\ 6 Pro';
                            font-weight: 400;
                            left: inherit;
                            position: absolute;
                            right: 26px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        .labelpadd {
                            padding: 0;
                            font-weight: 500;
                            margin-left: 0;
                            &.multilocation-business-label {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    /*- END: Add member -*/
}
/*- For Ios -*/
.iosmobiledevice {
    .vcMobile {
        // .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages {
        //     background: #000 !important;
        //     color: #fff !important;
        // }
        // .messagesArea-middle:after {
        //     // background: linear-gradient(180deg, #0d6492 0%, #332555 100%);
        //     // background: linear-gradient(180deg, #004e78 0%, #38226c 100%);
        //     background: var(--Gradient-Linear, linear-gradient(180deg, #0A6A9F 0%, #533C89 100%));
        //     mix-blend-mode: screen;
        // }

        .vcprimary-btn {
            background: linear-gradient(148.99deg, #076A9F 8.76%, #533C89 103.94%);
            border: none;
            color: #FFF;

            &:focus,
            &:active,
            &:hover {
                background: linear-gradient(144.78deg, #076A9F -45.07%, #533C89 67.63%);
            }
        }
    }
}

/*- END: new theme phase 2 -*/
//Start new Hariom for App
.vcMobile {
    .mobileHeaderNew-btnArea .fa-plus {
        font-size: 16px;
    }

    .mobileHeaderNew-btnArea .fa-bars-filter {
        font-size: 16px;
    }

    .vConnectMainWrap .dropdown-item.remove-chat-red {
        color: #c5392f !important;
    }

    .vConnectMainWrap .rightside .rightside-wrapper {
        background-color: #f8f8f8 !important;
    }

    .vConnectMainWrap .rightside-wrapper .accordion-body {
        padding: 4px 16px;
        background-color: #fff;
        border-radius: 10px;
        margin-bottom: 16px !important;
    }

    .vConnectMainWrap .rightside-wrapper.client-details .profile-wrapper {
        margin-bottom: 0px !important;
        display: flex;
        flex-direction: column;
    }

    .profile-wrapper.services-list-wrap .profile-image {
        order: 1;
    }

    .profile-wrapper.services-list-wrap .name-wrap {
        order: 2;
    }

    .profile-wrapper.services-list-wrap .customer-detail-wrap {
        order: 3;
        margin-top: 0;
        margin-bottom: 8px !important;
    }

    .profile-wrapper.services-list-wrap .membership-wrap {
        order: 4;
    }

    .vConnectMainWrap .rightside-wrapper .accordion-item .accordion-header .accordion-button:after {
        left: 0;
        right: auto;
    }

    .vConnectMainWrap .rightside-wrapper .appointment-detail {
        padding: 12px 0 12px 0;
        border-bottom: solid #e7e7e7 1px;
        align-items: center;
    }

    .vConnectMainWrap .rightside-wrapper .appointment-detail:last-of-type {
        border-bottom: 0px;
    }

    .vConnectMainWrap .rightside-wrapper .appointment-detail .service-wrap .date-wrap {
        font-size: 14px;
        line-height: 20px;
    }

    .vConnectMainWrap .rightside .dropdown-toggle {
        white-space: normal;
        margin: 0 !important;
        padding: 0 !important;
    }

    .vConnectMainWrap .rightside-wrapper .popup-note-wrap .note {
        margin: 0;
    }

    .vConnectMainWrap .rightside-wrapper .profile-wrapper .name-wrap h3 {
        margin: 0px 0px 8px 0;
    }

    .vConnectMainWrap .rightside-wrapper .contact-wrap a {
        align-items: center;
        justify-content: center;
    }

    .vConnectMainWrap .rightside-wrapper .appointment-detail .date:first-of-type {
        padding-top: 8px;
    }

    .vConnectMainWrap .rightside-wrapper .appointment-detail .date {
        font-size: 14px;
        line-height: 20px;
    }

    .vConnectMainWrap .rightside-wrapper .services-list-wrap.purchases-wrap .appointment-detail {
        padding: 12px 0;
    }

    .mobile-contextmenu.serviceCardWrap {
        margin-bottom: 16px;
    }

    .detailsActive .personal-info .avatar {
        width: 96px;
        height: 96px;
        border-radius: 100%;
    }
    .detailsActive .personal-info .avatar[data-avatar="2"] {
        .avatar-group-img-wrap {
            height: 96px;
            img {
                width: 100%;
            }
        }

    }

    .detailsActive .personal-info .avatar[data-avatar="1"] .avatar-group-img-wrap img {
        width: 96px;
        height: 96px;
    }

    .detailsActive .personal-info .avatar[data-avatar="1"] .avatar-group-img-wrap {
        width: 97px;
        height: 97px;
    }

    .detailsActive .personal-info .avatar[data-avatar="3"] .avatar-group-img-wrap:first-child {
        width: 97px;
        height: 97px;
    }

    .vConnectMainWrap .customer-detail-wrap {
        margin-top: 0px;
    }

    .detailsActive .personal-info .avatar[data-avatar="1"] .review-profile-inner-text {
        font-size: 18px;
    }

    .vConnectMainWrap .rightside-wrapper .contact-wrap a {
        font-size: 16px;
    }

    .vConnectMainWrap .rightside .businessLocationBlock .locationList .location {
        font-size: 16px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail .contact-wrap a {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail .customer-detail-wrap {
        margin-top: 0;
        margin-bottom: 0px;
        /*padding-bottom: 16px;*/
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail .businessLocationWrap:not(:empty) {
        border-top: solid 1px #e7e7e7;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail .businessLocationWrap {
        .contact-wrap {
            margin-bottom: 8px;
        }
        .far.fa-clock {
            font-size: 16px;
        }
    }

    .vConnectMainWrap .rightside-wrapper.client-details .services-list-wrap {
        margin-bottom: 0px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail .bottom-content-wrap {
        max-height: 100%;
        overflow: auto;
        padding: 16px !important;
        background-color: #fff;
        border-radius: 10px;
        margin: 16px;
    }

    // .detailsActive .personal-info .avatar .avatar-group-img-wrap-text { text-align: center; padding-left: 0; padding-top: 5px;}
    .detailsActive .personal-info .avatar:not([data-avatar="1"]) .avatar-group-img-wrap div .review-profile-inner-text {
        font-size: 12px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .members-list-wrap {
        max-height: calc(100% - 80px);
        overflow: auto;
        padding: 16px !important;
        background-color: #fff;
        border-radius: 10px;
    }

    .rightside-wrapper .accordion-item .accordion-header .accordion-button {
        box-shadow: none !important;
        padding-left: 18px !important;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person .avatar {
        height: 50px;
        width: 50px;
        flex: 0 0 50px;
        border-radius: 100%;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .avatar-sm[data-avatar="1"] .avatar-group-img-wrap {
        width: 51px;
        height: 51px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .avatar-sm[data-avatar="1"] .avatar-group-img-wrap img {
        width: 50px;
        height: 50px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-header {
        display: none;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person .avatar .review-profile-inner-text {
        width: 51px;
        height: 51px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person_number {
        font-size: 14px;
        line-height: 18px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person_name {
        font-size: 16px;
        line-height: 22px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person:last-of-type {
        margin-bottom: 0px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person:first-of-type {
        margin-top: 0px;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .member-list-wrapper .chatList-person_dotMenu {
        right: 0;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .personal-info .avatar-group-img-wrap-text {
        font-size: 12px;
        /*-font-weight: 600;-*/
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .team-employee-name {
        display: flex;
        align-items: center;
        align-self: stretch;
        margin-bottom: 16px;
    }

    .add-member-dialogue .modal-content {
        margin-top: 0;
    }

    .block-status {
        order: 5;
    }

    .vConnectMainWrap .rightside-wrapper.employee-detail-page .team-employee-name .chatList-person_name {
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        font-size: 20px;
    }

    .team-heading {
        display: block !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        text-align: left;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .vConnectMainWrap .rightside-wrapper .personal-info {
        padding-top: 24px !important;
    }

    .vConnectMainWrap .rightside .businessLocationBlock .locationList .location {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .vConnectMainWrap .rightside .businessLocationBlock .locationList .locationToogle {
        font-size: 14px;
        padding-top: 5px;
    }

    .vConnectMainWrap .rightside-wrapper .contact-wrap a {
        margin-bottom: 3px;
    }

    .vConnectMainWrap .rightside-wrapper .profile-wrapper .name-wrap h3 {
        margin-bottom: 3px;
    }

    .vConnectMainWrap .rightside-wrapper.client-details .personal-info {
        padding-bottom: 10px;
    }

    .vConnectMainWrap .chatarea .chatListBlock .chatList-person.personRead .chatList-person_name {
        font-weight: 500 !important;
    }

    .vConnectMainWrap .chatarea .chatListBlock-searchWrap .searchIcon:after {
        color: var(--text_neutral_secondary);
    }

    .vConnectMainWrap .pinnedGroupMobile .chatList-person_name {
        width: 60px;
        margin: 0 auto;
        text-align: center;
    }

}

// ipad landspace 
.vcTab-portrait {

    #note1,
    #Ghippy1,
    #Vagaro_Links1,
    #Vagaro_files1,
    #Emoji1 {
        display: block !important;
    }
}

.vcTab .moRightSlideTrigger {
   display: none!important;
}

@media (min-width: 950px) {

    .vcTab {
        @include chatAreaGradiant;
        .vConnectMainWrap .chatarea .messagesArea-header {
            position: relative;
            z-index: 4;
        }
        .vConnectMainWrap .chatarea .messagesArea-middle .messagesBox.sent .messages {
            background: #000 !important;
            color: #fff !important;

        }

        .messagesArea-middle:after {
            left: 0;
            width: auto;
            height:calc(100vh - 195px);
            top: 50px;
            bottom: 137px;
        }

        .vConnectMainWrap .chatarea .messagesArea-footer {
            position: static;
        }

    }
}

.image-popup-wrap {
    .modal-body {
        .react-transform-wrapper {
            margin: 0 auto;
        }
    }
}

.user-heading-name {
    width:auto;
    max-width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;}
.vcTab {
    .messagesBox.messagesEditWrap .typeMsgText { position: relative; z-index: 2; }
   
}

.vcMobile {
    .employee-multilocation-select.select2-container--default .select2-results__option:has(.defultselected) {background: var(--bkg_neutral_secondary) !important;}
    .employee-multilocation-select.select2-container--default .select2-results__option:has(.defultselected):after{
    content: "\f00c";
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    right: 26px;
    left: inherit;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: var(--Connect-Primary-Button-Default, linear-gradient(149deg, #076A9F 8.76%, #533C89 98.52%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

}
.messagesEditWrap .mobileListmenuWrap {border-radius: 15px;}
// .messagesEditWrap .mobileListmenuWrap .ckeditor_2 { border-radius: 15px;  overflow: hidden;}

@media only screen and (min-width: 700px) and (max-width: 1024px) {
    .vcMobile {
        .vConnectMainWrap.chatActive.rightSideOnly {
            .typeChatWrap.collapsibleChat.mobileActiveChat {
                .ckeditor_1 {
                    .ck.ck-reset.ck-editor {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        .ck.ck-editor__top {
                            margin-bottom: 0;
                            margin-left: 17px;
                            .cke_button.cke_button_Send {
                                position: static;
                            }
                        }
                        .ck.ck-editor__main {
                            width: 100% !important;
                        }
                    }
                    
                }
                .iconchat {
                    right: 85px;
                }
            }
        }
    }
}

//For mobile css
.vcMobile:not(.vcTab-portrait) {
    .deactivate-wrap { display: block;}
   
.connect-right-wrapper { margin: 0;}
    
    .settingfooter { padding: 16px; gap: 0; background-color:var(--bkg_neutral_default); border: 0; box-shadow: none;}
    .connet-mid-wrapper { padding-bottom: 0px; background-color: #f8f8f8; height: calc(100vh - 45px);}
    // .mob-app-hide-text { display: none;}
    .takecontrol-banner, .takecontrol-border {
        border-radius: 24px;
    }
    .banner-heading { font-size: 30px; line-height: 36px; font-weight: 700;}
    .banner-discription-text { line-height: 28px; font-size: 20px; font-weight: 500; padding-top: 6px;padding-bottom: 6px;}
    .connect-setting-left { display: none;}
    .connect-right-wrapper { padding: 24px 16px 0px 16px;}
    .connect-ai-banner { flex-direction: column; width: 100%;}
    .ai-banner-left { padding: 0px;}
    .connect-settingWrap .ai-setting-banner { padding-bottom: 0; padding-top: 0px;}
    .ai-banner-right { overflow: hidden; margin-left: -16px; margin-right: -16px;   width: auto!important; margin-top: 16px;}
    .m-image-wrap { margin-left: -16px; margin-right: -16px;}
    .ai-banner-left, .ai-banner-right { width: 100%; }
    .settingfooter .vcgradientbtn { b: 13px!important;}
    .connect-settingWrap .deactivate-wrap { display: block; padding-bottom: 0;}
    .mob-show, .show-div-app { display: block;}
    .bottom-show-footer .connect-right-wrapper{
        padding-bottom: 0;
    }

}